<template>
  <div class='relative w-full bg-[#1e3c53] rounded-xl shadow px-3 mb-5'
  @keydown.esc="showFilter = false">
    <div class="flex justify-between items-center py-3 relative">
      <div class="mx-auto flex gap-2">
        <SearchBar
          uniqueSearchBarName='historyTabSearchBar'
          :currentFetchParams='currentFetchParams'
          @update-fetch-params="updateFetchParams"/>
        <button @click="showFilter = !showFilter"
          class="bg-sky-500 text-white rounded-full hover:bg-sky-700
          transition-colors duration-300 w-9 h-9 p-1 hover:scale-105"
          :title="$t('transitHistoryTab.showTransitsFilter')">
          <img :src="filterIcon" alt="filter icon">
        </button>
        <button @click="printReport('csv')" :disabled="this.isLoading"
          class="bg-sky-500 rounded hover:bg-sky-700 transition-colors
          duration-300 w-9 h-9 p-1 ml-3 hover:scale-105 disabled:bg-gray-400
          disabled:scale-100"
          :title="$t('transitHistoryTab.csvReport')">
          <img :src="csvReportIcon"
          alt="filter icon">
        </button>
        <button @click="printReport('xlsx')" :disabled="this.isLoading"
          class="bg-sky-500 rounded hover:bg-sky-700 transition-colors
          duration-300 w-9 h-9 p-1 ml-3 hover:scale-105 disabled:bg-gray-400
          disabled:scale-100"
          :title="$t('transitHistoryTab.xlsxReport')">
          <img :src="xlsxReportIcon"
          alt="filter icon">
        </button>
      </div>
      <PaginationControl class="absolute right-0"
        v-model:elementsPerPage='elementsPerPage'
        v-model="currentPage"
        v-model:totalItems='totalItems'
        @update:elementsPerPage="handleElementsPerPageChange"
        />
    </div>
    <hr class="border-t border-sky-300 mb-2"/>
    <div v-show="showFilter"
      class="fixed top-0 left-0 w-full h-full z-40
      bg-black bg-opacity-50 flex justify-center items-center"
      @click="showFilter = false"
      @keydown.esc="showFilter = false">
      <TransitFilterPopUp
        @close="showFilter = false"
        :currentFetchParams='currentFetchParams'
        @update-fetch-params="updateFetchParams"/>
    </div>
    <div>
      <LoadingOverlay
      v-if="isLoading"
      :show="isLoading"
      :additionalText="additionalText"/>
      <div v-else-if="transitStore.errors.fetchTransits"
        class="p-4 mt-4 border rounded border-red-500 text-red-500">
        {{ transitStore.errors.fetchTransits }}
      </div>
      <div v-else-if="transitStore.transit.isNoContent"
        class="p-4 mt-4 border rounded bg-sky-100 border-sky-500 text-sky-800">
        <p class="text-2xl font-bold">{{ $t('transitHistoryTab.noTransitsToShow') }}</p>
      </div>
      <div v-else class="flex flex-wrap justify-center">
        <TransitCard
            v-for="transit in transitStore.transit.transits"
            :key="transit.id"
            :transit="transit"
        />
      </div>
      <div class="flex justify-center mt-2 pb-5">
        <PaginationControl class="col-start-4 place-content-end"
          v-show="!this.isLoading"
          v-model:elementsPerPage='elementsPerPage'
          v-model="currentPage"
          v-model:totalItems='totalItems'
          @update:elementsPerPage="handleElementsPerPageChange"/>
      </div>
    </div>
    <PopupImage
        :show="showPopup"
        :section="selectedSection"
        @close="showPopup = false"
      />
    <PopupVideo
      :show="showVideoPopup"
      :transit="this.selectedVideoTransit"
      @close="showVideoPopup = false"/>
  </div>
</template>

<script>
import moment from 'moment';
import { getTimestampFromLocalDate } from '@/utils/date';
import { getIcon } from '@/utils/icons';
import useTransitStore from '@/stores/TransitStore';

import PaginationControl from './PaginationControl.vue';
import PopupImage from './PopUpImage.vue';
import PopupVideo from './PopUpVideo.vue';
import TransitCard from './TransitCard.vue';
import TransitFilterPopUp from './TransitFilterPopUp.vue';
import SearchBar from './SearchBar.vue';
import LoadingOverlay from './LoadingOverlay.vue';

export default {
  name: 'TransitHistoryTab',
  components: {
    PaginationControl,
    TransitCard,
    TransitFilterPopUp,
    SearchBar,
    PopupImage,
    PopupVideo,
    LoadingOverlay,
  },
  data() {
    return {
      elementsPerPage: 50,
      currentPage: 1,
      totalItems: 0,
      filterIcon: getIcon('filterIcon'),
      showFilter: false,
      currentFetchParams: {},
      isLoading: true,
      showPopup: false,
      selectedSection: {},
      showVideoPopup: false,
      selectedVideoTransit: {},
      additionalText: '',
    };
  },
  setup() {
    const transitStore = useTransitStore();
    const xlsxReportIcon = getIcon('xlsxReportIcon');
    const csvReportIcon = getIcon('csvReportIcon');
    return {
      transitStore,
      xlsxReportIcon,
      csvReportIcon,
    };
  },
  methods: {
    async navigatePages() {
      const maxPage = Math.ceil(this.totalItems / this.elementsPerPage);
      if (this.currentPage > maxPage) {
        this.currentPage = 1;
      }
      this.currentFetchParams = {
        ...this.currentFetchParams,
        pageNumber: this.currentPage - 1,
        pageSize: this.elementsPerPage,
      };
      try {
        this.isLoading = true;
        await this.transitStore.fetchTransits(this.currentFetchParams);
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    async printReport(reportExtension) {
      this.setReportRequested(true);
      this.emitter.emit('show-toast', { toastType: 'info', toastText: this.$t(`transitHistoryTab.${reportExtension}BeingGenerated`) });
      const reportType = reportExtension === 'xlsx' ? 'excel' : reportExtension;
      const reportDict = { header: `text/${reportExtension}`, extension: reportExtension, report_type: reportType };
      this.currentFetchParams.reportDict = reportDict;
      const data = await this.transitStore.fetchTransitsReport(this.currentFetchParams);
      if (data) {
        const reportBlob = URL.createObjectURL(data.blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = reportBlob;
        a.download = data.filename ? data.filename : `${this.getReportFileName()}.${reportDict.extension}`;
        a.click();
        a.remove();
        URL.revokeObjectURL(reportBlob);
      } else {
        this.emitter.emit('show-toast', { toastType: 'error', toastText: this.$t('transitHistoryTab.errorPrintingReport') });
      }
      this.setReportRequested(false);
    },
    setReportRequested(isReportRequested) {
      this.additionalText = isReportRequested ? this.$t('transitHistoryTab.generatingReport') : '';
      this.isLoading = isReportRequested;
    },
    getReportFileName() {
      if (this.transitStore.transit.count > 0) {
        return `Transits_${moment().format('YYYYMMDD_HHmmss')}`;
      }
      return 'NoTransits';
    },
    async updateFetchParams(params) {
      this.isLoading = true;
      this.currentFetchParams = params;
      this.currentFetchParams.pageNumber = 0;
      this.currentPage = 1;
      try {
        await this.transitStore.fetchTransits(this.currentFetchParams);
        this.totalItems = this.transitStore?.transit?.count ?? 0;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    handleElementsPerPageChange(value) {
      this.elementsPerPage = value;
      this.navigatePages();
    },
    async openPopupEvent(section) {
      this.selectedSection = section;
      this.showPopup = true;
    },
    async openVideoPopupEvent(transit) {
      this.showVideoPopup = true;
      this.selectedVideoTransit = transit;
    },
    restoreSessionFetchParams() {
      if (sessionStorage.locationIds) {
        this.currentFetchParams.locations = Object(JSON.parse(sessionStorage.locationIds));
      }
      if (sessionStorage.filterParams) {
        const filters = Object(JSON.parse(sessionStorage.filterParams));
        if (filters.fromDate) {
          this.currentFetchParams.initialTime = getTimestampFromLocalDate(filters.fromDate);
        }
        if (filters.toDate) {
          this.currentFetchParams.finishTime = getTimestampFromLocalDate(filters.toDate);
        }
      }
      if (sessionStorage.historyTabSearchBar) {
        this.currentFetchParams.searchValue = JSON.parse(sessionStorage.historyTabSearchBar);
      }
    },
  },
  async created() {
    try {
      this.transitStore.cleanTransits();
      await this.transitStore.fetchLocation();
      this.emitter.emit('locations-updated', this.transitStore.locations);
    } catch (err) {
      console.error(err);
    }
  },
  beforeMount() {
    this.restoreSessionFetchParams();
  },
  mounted() {
    this.emitter.on('click-on-forensic', this.openPopupEvent);
    this.emitter.on('click-on-video', this.openVideoPopupEvent);
  },
  unmounted() {
    this.emitter.off('click-on-forensic');
  },
};
</script>
