export default function statsValidator(data) {
  if (!data || !Array.isArray(data.elements)) {
    throw new Error("Invalid data: 'elements' should be an array.");
  }

  data.elements.forEach((element) => {
    if (typeof element.start_time !== 'string') {
      throw new Error("Invalid data: 'start_time' should be a string.");
    }
    if (typeof element.end_time !== 'string') {
      throw new Error("Invalid data: 'end_time' should be a string.");
    }
    if (typeof element.type !== 'string') {
      throw new Error("Invalid data: 'type' is not valid.");
    }
    if (typeof element.count !== 'number') {
      throw new Error("Invalid data: 'count' should be a number.");
    }
    if (typeof element.average_confidence !== 'number' || element.average_confidence > 1) {
      throw new Error("Invalid data: 'average_confidence' is not valid.");
    }
  });
}
