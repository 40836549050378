<template>
  <div ref="popup" class="flex flex-col bg-gray-200 border border-gray-300 rounded-xl" @click.stop>
    <div class="relative bg-white rounded-xl m-3 p-3">
      <div>
        <div class="flex flex-row gap-5">
          <div class="flex items-center gap-2">
            <div class="flex flex-col gap-4">
              <div class="flex items-center justify-between gap-2">
                <label for="fromDate" class="flex-grow">
                  {{ $t('commonFilterPopUp.from') }}
                  <input id="fromDate" v-model="this.dateFilter.fromDate" type="datetime-local"
                    class="ml-2 p-2 rounded border border-gray-300 flex-grow" />
                </label>
              </div>
              <div class="flex items-center justify-between gap-2">
                <label for="toDate" class="flex-grow">
                  {{ $t('commonFilterPopUp.to') }}
                  <input id="toDate" v-model="this.dateFilter.toDate" type="datetime-local"
                    class="ml-5 p-2 rounded border border-gray-300 flex-grow" />
                </label>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <button
                v-for="(value, key) in dateLabelsEnum"
                :key="key"
                @click="setActiveButton(key)"
                :class="[
                  'py-1 px-6 rounded-full border hover:text-sky-700 transition',
                  activeKey === key
                    ? 'bg-sky-500 text-white hover:bg-sky-700 hover:text-white'
                    : 'bg-white'
                ]">
                {{ value }}
              </button>
            </div>
          </div>
          <div
            class="inline-block min-h-[1em] w-0.5 self-stretch bg-sky-300">
          </div>
          <div>
            <div class="flex flex-col items-center justify-center gap-6 object-right m-3">
              <LocationFilter
              ref="locationFilter"
              @update:filterByLocation="handleFilterByLocationChange"
              uniqueComboName="transitFilterPopupLocationCombo"/>
              <TransitTypeFilter
              ref="transitTypeFilter"
              @update:filterByTransitType="handleFilterByTransitTypeFilterChange"
              uniqueComboName="transitFilterPopupTransitTypeCombo"/>
              <TransitDirectionFilter
              ref="transitDirectionFilter"
              @update:filterByTransitDirection="handleFilterByTransitDirectionFilterChange"
              uniqueComboName="transitFilterPopupTransitDirectionCombo"/>
              <button
                v-show="hasReadEmptyTransitsPermission()"
                class="flex items-center gap-2" v-on:click="showEmptyTransits = !showEmptyTransits">
                <i class="w-5 h-5">
                  <img :src="getIcon(showEmptyTransits ? 'checkboxChecked' : 'checkboxUnchecked')" class="w-5 h-5 ml-1 mr-2" alt="checkbox icon" />
                </i>
                {{ $t('commonFilterPopUp.showEmptyTransits')}}
              </button>
            </div>
          </div>
        </div>
        <div>
          <hr class="border-t-1 border-dashed border-gray-300 mt-4 mb-2"/>
          <div class="flex gap-6 justify-between">
            <button @click="clearFilter"
              class="mt-2 px-4 py-2 text-gray-600 hover:font-bold underline underline-offset-4 hover:decoration-4 hover:text-rose-950"
              :title="$t('commonFilterPopUp.clear')">
              {{ $t('commonFilterPopUp.clear') }}
            </button>
            <button @click="applyFilter"
              class="mt-2 px-3 py-1 bg-sky-500 text-white rounded
                hover:bg-sky-700 transition-colors duration-300"
              :title="$t('commonFilterPopUp.applyFilter')">
              {{ $t('commonFilterPopUp.applyFilter') }}
            </button>
          </div>
        </div>
      </div>
      <button @click="$emit('close')"
        class="absolute -top-2 -right-2"
        :title="$t('commonFilterPopUp.closePopup')">
        <svg width="36px" height="36px" viewBox="0 0 24 24" fill="none"
        xmlns="http://www.w3.org/2000/svg" class="bg-white rounded-full hover:bg-gray-200">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2
            12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"></path>
            <path d="M8.96967 8.96967C9.26256 8.67678 9.73744 8.67678 10.0303 8.96967L12
            10.9394L13.9697 8.96969C14.2626 8.6768 14.7374 8.6768 15.0303 8.96969C15.3232
            9.26258 15.3232 9.73746 15.0303 10.0304L13.0607 12L15.0303 13.9696C15.3232
            14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696
            15.0303L12 13.0607L10.0304 15.0303C9.73746 15.3232 9.26258 15.3232 8.96969
            15.0303C8.6768 14.7374 8.6768 14.2626 8.96969 13.9697L10.9394 12L8.96967
            10.0303C8.67678 9.73744 8.67678 9.26256 8.96967 8.96967Z" fill="#000000"></path>
          </g>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { getTimestampFromLocalDate, getLocalDateTimeFromTimestampInFilter } from '@/utils/date';
import { getIcon } from '@/utils/icons';
import useUserManagementStore from '@/stores/UserManagementStore';

import LocationFilter from './LocationFilter.vue';
import TransitTypeFilter from './TransitTypeFilter.vue';
import TransitDirectionFilter from './TransitDirectionFilter.vue';

export default {
  name: 'TransitFilterPopUp',
  components: {
    LocationFilter,
    TransitTypeFilter,
    TransitDirectionFilter,
  },
  props: {
    currentFetchParams: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      getIcon,
    };
  },
  data() {
    return {
      dateFilter: {
        fromDate: '',
        toDate: '',
      },
      showEmptyTransits: false,
      dateLabelsEnum: {
        today: this.$t('commonFilterPopUp.today'),
        week: this.$t('commonFilterPopUp.lastWeek'),
        month: this.$t('commonFilterPopUp.lastMonth'),
        year: this.$t('commonFilterPopUp.lastYear'),
      },
      activeKey: null,
    };
  },
  methods: {
    applyFilter() {
      const updatedParams = { ...this.currentFetchParams };
      if (this.dateFilter.fromDate) {
        updatedParams.initialTime = getTimestampFromLocalDate(this.dateFilter.fromDate);
      } else {
        delete updatedParams.initialTime;
      }
      if (this.dateFilter.toDate) {
        updatedParams.finishTime = getTimestampFromLocalDate(this.dateFilter.toDate);
      } else {
        delete updatedParams.finishTime;
      }
      updatedParams.activeKey = this.activeKey;
      updatedParams.includeEmptyTransits = this.showEmptyTransits;
      sessionStorage.transitFilterParams = JSON.stringify(updatedParams);
      updatedParams.locations = this.selectedLocations;
      updatedParams.transitTypes = this.selectedTransitTypes;
      updatedParams.transitDirection = this.selectedTransitDirection;
      this.$emit('update-fetch-params', updatedParams);
      this.close();
    },
    hasReadEmptyTransitsPermission() {
      return useUserManagementStore().hasPermissionName('read_empty_transits');
    },
    setActiveButton(key) {
      this.activeKey = this.activeKey === key ? null : key;
      if (this.activeKey === null) return;
      this.dateFilter.toDate = moment().format('YYYY-MM-DDTHH:mm');
      if (key === 'today') {
        this.dateFilter.fromDate = moment().format('YYYY-MM-DDT00:00');
      } else {
        this.dateFilter.fromDate = moment().subtract(1, key).format('YYYY-MM-DDTHH:mm');
      }
    },
    clearFilter() {
      this.dateFilter = {
        toDate: '',
        fromDate: '',
      };
      this.activeKey = null;
      this.showEmptyTransits = false;
      this.$refs.locationFilter.selectAllOptions();
      this.$refs.transitTypeFilter.selectAllOptions();
      sessionStorage.removeItem('transitFilterPopupLocationCombo');
      sessionStorage.removeItem('transitFilterPopupTransitTypeCombo');
      sessionStorage.removeItem('transitFilterPopupTransitDirectionCombo');
      sessionStorage.removeItem('transitFilterParams');
    },
    close() {
      this.$emit('close');
    },
    handleFilterByLocationChange(selectedLocations) {
      this.selectedLocations = selectedLocations;
    },
    handleFilterByTransitTypeFilterChange(selectedTransitTypes) {
      this.selectedTransitTypes = selectedTransitTypes;
    },
    handleFilterByTransitDirectionFilterChange(selectedDirection) {
      this.selectedTransitDirection = selectedDirection;
    },
  },
  beforeMount() {
    if (sessionStorage.transitFilterParams) {
      const transitFilterParams = Object(JSON.parse(sessionStorage.transitFilterParams));
      if (transitFilterParams.initialTime) {
        this.dateFilter.fromDate = getLocalDateTimeFromTimestampInFilter(transitFilterParams.initialTime * 1000);
      }
      if (transitFilterParams.finishTime) {
        this.dateFilter.toDate = getLocalDateTimeFromTimestampInFilter(transitFilterParams.finishTime * 1000);
      }
      this.showEmptyTransits = transitFilterParams.includeEmptyTransits;
      this.activeKey = transitFilterParams.activeKey;
    }
    if (sessionStorage.transitFilterPopupLocationCombo) {
      this.selectedLocations = [...JSON.parse(sessionStorage.transitFilterPopupLocationCombo)];
    }
    if (sessionStorage.transitFilterPopupTransitTypeCombo) {
      this.selectedTransitTypes = [...JSON.parse(sessionStorage.transitFilterPopupTransitTypeCombo)];
    }
    if (sessionStorage.transitFilterPopupTransitDirectionCombo) {
      const transitDirectionFilter = JSON.parse(sessionStorage.transitFilterPopupTransitDirectionCombo);
      if (transitDirectionFilter?.id !== 'both') {
        this.selectedTransitDirection = transitDirectionFilter?.id;
      }
    }
    this.applyFilter();
  },
  mounted() {
    this.$nextTick(() => this.$refs.popup.focus());
  },
};
</script>
