import { createI18n } from 'vue-i18n';
import { viewerLanguage } from '@/../TransitViewerConfig';
import de from './de.json';
import en from './en.json';
import es from './es.json';
import ca from './ca.json';
import eu from './eu.json';
import pt from './pt.json';
import fr from './fr.json';

const viewerLocale = viewerLanguage() || 'en';
const i18n = createI18n({
  locale: viewerLocale,
  fallbackLocale: 'en',
  legacy: false,
  messages: {
    en,
    de,
    es,
    ca,
    eu,
    pt,
    fr,
  },
});

export default i18n;
