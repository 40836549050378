<template>
  <div class="flex gap-2 items-center">
    <div
    v-for="selectedOption in this.selectedOptions"
    v-bind:key="selectedOption.id"
    class="flex items-center justify-center bg-transparent pl-2 border border-gray-100 rounded-full gap-1">
      <p class="text-sm">{{ getAliasOrNameFromObject(selectedOption) }}</p>
      <button v-show="this.doNotShowSelectButton"
        @click="unselectOption(selectedOption)"
        class="hover:bg-gray-200 p-2 rounded-full">
        <i>
          <img class="min-w-max h-2.5" :src="this.removeOptionIcon" alt="Dropdown button icon"/>
        </i>
      </button>
    </div>
    <IconDropdownMenu
    :comboOptions="this.availableOptions"
    :preselectedOptions="this.selectedOptions"
    :notShowIfSelected=true
    :buttonIcon="this.buttonIcon"
    :buttonItemIcon="this.buttonItemIcon"
    @update:selection-changed="updateSelectedOptions"/>
  </div>
</template>

<script>
import IconDropdownMenu from '@/components/generic/IconDropdownMenu.vue';
import { getIcon } from '@/utils/icons';
import getAliasOrNameFromObject from '@/utils/objects';

export default {
  name: 'SelectorWithTags',
  emits: ['selection-changed'],
  props: {
    doNotShowSelectButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    availableOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
    buttonIcon: {
      type: String,
      required: true,
    },
    buttonItemIcon: {
      type: String,
      required: true,
    },
    preselectedOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    IconDropdownMenu,
  },
  data() {
    return {
      removeOptionIcon: getIcon('crossIcon'),
      selectedOptions: this.preselectedOptions,
    };
  },
  setup() {
    return {
      getAliasOrNameFromObject,
    };
  },
  methods: {
    updateSelectedOptions(selectedOptionIds) {
      this.selectedOptions = [];
      selectedOptionIds.forEach((selectedOptionId) => {
        const selectedOption = this.availableOptions.find((option) => option.id === selectedOptionId);
        if (this.selectedOptions.includes(selectedOption)) {
          return;
        }
        this.selectedOptions.push(selectedOption);
      });
      this.$emit('selection-changed', this.selectedOptions);
    },
    unselectOption(option) {
      const unselectedOptionIndex = this.selectedOptions.indexOf(option);
      this.selectedOptions.splice(unselectedOptionIndex, 1);
      this.$emit('selection-changed', this.selectedOptions);
    },
  },
  watch: {
    preselectedOptions() {
      this.selectedOptions = this.preselectedOptions;
    },
  },
};
</script>
