<template>
  <SingleSelectCombo
    ref="singleSelectCombo"
    :buttonText="this.$t('transitDirectionFilter.transitDirection')"
    :uniqueComboName="this.uniqueComboName"
    :comboOptions="this.transitDirectionArray"
    @comboSelectionChanged="transitDirectionFilterUpdated"/>
</template>

<script>
import SingleSelectCombo from './generic/SingleSelectCombo.vue';

export default {
  name: 'TransitDirectionFilter',
  components: {
    SingleSelectCombo,
  },
  data() {
    return {
      transitDirectionArray: [
        { id: 'both', value: this.$t('transitDirectionFilter.both') },
        { id: 'in', value: this.$t('transitDirectionFilter.in') },
        { id: 'out', value: this.$t('transitDirectionFilter.out') },
      ],
    };
  },
  props: {
    uniqueComboName: {
      type: String,
      required: true,
    },
  },
  methods: {
    transitDirectionFilterUpdated(optionId) {
      let processedOptionId = optionId?.id;
      if (processedOptionId === 'both') {
        processedOptionId = null;
      }
      this.$emit('update:filterByTransitDirection', processedOptionId);
    },
  },
};
</script>
