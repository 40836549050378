<template>
  <div v-if="show"
    ref="popupVideoViewer"
    class="fixed inset-0 z-20 flex items-center justify-center bg-[rgba(0,0,0,0.5)]"
    @click="closePopup()"
    @keyup.esc="closePopup()"
    tabindex="0">
    <ToastComponent ref="toastOnPopUp" class="mx-auto"/>
    <div
      class="bg-gray-200 relative p-5 w-4/5 flex flex-col max-h-screen overflow-auto rounded-xl"
      @click.stop>
      <button @click="closePopup()"
        class="absolute top-3 right-3"
        :title="this.$t('popUpImage.closePopup')">
        <svg width="36px" height="36px" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg" class="bg-white rounded-full hover:bg-gray-200">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2
              12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"></path>
            <path d="M8.96967 8.96967C9.26256 8.67678 9.73744 8.67678 10.0303 8.96967L12
              10.9394L13.9697 8.96969C14.2626 8.6768 14.7374 8.6768 15.0303 8.96969C15.3232
              9.26258 15.3232 9.73746 15.0303 10.0304L13.0607 12L15.0303 13.9696C15.3232
              14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696
              15.0303L12 13.0607L10.0304 15.0303C9.73746 15.3232 9.26258 15.3232 8.96969
              15.0303C8.6768 14.7374 8.6768 14.2626 8.96969 13.9697L10.9394 12L8.96967
              10.0303C8.67678 9.73744 8.67678 9.26256 8.96967 8.96967Z" fill="#000000"></path>
          </g>
        </svg>
      </button>
      <div class="flex flex-row gap-3 rounded-xl">
        <div class="w-full object-contain h-full relative self-center justify-center align-middle">
          <div
            v-if="!isFetchingVideo"
            :class="this.fetchError ? '' : 'bg-[#1e3c53] rounded-md p-2'">
            <!-- eslint-disable-next-line -->
            <video
              v-if="thereAreVideos() && initialize() && !this.fetchError"
              controls
              class="flex justify-center items-center m-auto w-full h-full"
              :src="this.selectedVideo">
              {{ this.$t('popUpVideo.browserDoesNotSupportVideoTag') }}
            </video>
            <div v-else class="basis-[80%] p-4 mt-4 border rounded bg-red-50 border-red-200 text-red-800">
              <p class="text-2xl font-bold">
                {{ this.fetchError }}
              </p>
            </div>
          </div>
          <div v-else class="inline-flex align-middle justify-center
            p-4 border rounded-xl bg-sky-100 border-sky-500 text-sky-800 w-fit">
            <p class="flex text-2xl font-bold gap-6 items-center">
              {{ this.$t('popUpVideo.loadingVideo')}}
              <img :src="getIcon('loadingGif')" class="w-16 h-16" alt="Loading data gif"/>
            </p>
          </div>
        </div>
        <div class="basis-[20%] bg-white rounded-lg pt-5 px-3 flex flex-col gap-3">
          <div class="flex justify-center items-start gap-2">
            <img :src="this.locationIcon" class="w-14 h-14"
              alt="transit type icon">
          </div>
          <hr class="border-t-2 border-gray-300 my-2"/>
          <div class="grid grid-cols-3 cursor-default text-sm text-left w-auto gap-1 text-ellipsis overflow-hidden">
            <p>{{ this.$t('popUpImage.from') }}</p>
            <p class="col-span-2"><b>{{ this.selectedVideoData.streamName }}</b></p>
            <p>{{ this.$t('popUpImage.at') }}</p>
            <p class="col-span-2"><b>{{ getLocalDateTimeFromTimestamp(this.transit.start_time) }}</b></p>
            <p>{{ this.$t('transitCard.duration') }}</p>
            <p class="col-span-2"><b>{{ getDuration(this.transit.start_time, this.transit.end_time) }}</b></p>
          </div>
          <hr class="border-t-2 border-gray-300 mt-2"/>
          <div class="text-left bg-gray-200 rounded-xl max-h-[425px] overflow-auto">
            <div class="text-sm bg-gray-300 rounded-t-xl font-semibold border-b-2 border-gray-400 p-2 pt-3">
              {{ this.$t('popUpVideo.availableVideos') }}
            </div>
            <div v-for="(video, video_index) in this.videos" :key="video_index" class="relative">
              <button
                :class="['h-9 p-1 w-full text-left text-xs',
                        (index % 2 === 0) ? 'bg-gray-200' : 'bg-gray-100',
                        (this.selectedVideoData === video) ? 'bg-sky-200 text-sky-900' : 'hover:bg-sky-100']"
                @click="changeSelectedVideo(video)">
                <p>{{ this.$t('popUpVideo.videoFrom') }}<b>{{ video.streamName }}</b></p>
              </button>
              <button v-on:click="downloadVideo(video)"
                :disabled="video.disabled"
                class="absolute top-1 right-2 bg-white rounded-md w-fit h-fit px-2 py-1 transition-colors
                  duration-200 ease-in-out transform hover:bg-gray-300 hover:shadow-lg hover:scale-105 disabled:scale-100 disabled:bg-gray-50"
                  :title="this.$t('popUpVideo.downloadVideo')">
                <i> <img
                    :src="video.disabled ? getIcon('downloadVideoIconDisabled') : getIcon('downloadVideoIcon')"
                    alt="download video icon"
                    class="w-5 h-5"/>
                </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useTransitStore from '@/stores/TransitStore';
import closeButton from '@/assets/img/close-button.png';
import { getLocalDateTimeFromTimestamp, getDuration } from '@/utils/date';
import { getIconName, getIcon } from '@/utils/icons';
import getAliasOrNameFromObject from '@/utils/objects';
import ToastComponent from './generic/ToastComponent.vue';

export default {
  props: ['show', 'transit', 'transitType'],
  name: 'PopupVideo',
  components: {
    ToastComponent,
  },
  setup() {
    return {
      transitStore: useTransitStore(),
      getLocalDateTimeFromTimestamp,
      getDuration,
      getIcon,
    };
  },
  data() {
    return {
      closeButton,
      initialized: false,
      isFetchingVideo: false,
      fetchError: '',
      selectedVideo: '',
      selectedVideoData: {},
      selectedVideoBlob: '',
      videos: [],
      tryReloadVideoTimerId: null,
      tryReloadVideoCounter: 0,
      locationIcon: '',
    };
  },
  methods: {
    initialize() {
      if (!this.initialized) {
        if (this.videos.length > 0) {
          this.changeSelectedVideo(this.videos[0]);
        }
        this.initialized = true;
      }
      return true;
    },
    thereAreVideos() {
      return this.videos.length !== 0;
    },
    closePopup() {
      this.initialized = false;
      this.$emit('close');
    },
    updateTransitIcon() {
      const location = this.transitStore.locationMap.get(this.transit.location_id);
      this.locationIcon = getIcon(getIconName(location.transit_type));
    },
    updateTransitVideos() {
      this.videos.length = 0;
      this.transit.videos.forEach((video) => {
        const stream = this.transitStore.streamMap.get(video.stream_id);
        const streamAliasOrName = getAliasOrNameFromObject(stream);
        this.videos.push({
          streamName: streamAliasOrName,
          streamId: video.stream_id,
          transitId: this.transit.id,
          disabled: false,
        });
      });
    },
    takeFocusIfShown() {
      if (this.show) {
        this.updateTransitIcon();
        this.updateTransitVideos();
        this.$nextTick(() => this.$refs.popupVideoViewer.focus());
      }
    },
    async downloadVideo(downloadVideoObject) {
      try {
        const data = await this.transitStore.fetchVideo(downloadVideoObject.streamId, downloadVideoObject.transitId);
        const videoBlob = URL.createObjectURL(data.blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = videoBlob;
        a.download = data.filename;
        a.click();
        a.remove();
        URL.revokeObjectURL(videoBlob);
      } catch (error) {
        this.emitter.emit('show-toast', { toastType: 'error', toastText: error });
      }
    },
    async changeSelectedVideo(newSelectedVideoData) {
      if (this.selectedVideoData !== newSelectedVideoData) {
        this.isFetchingVideo = true;
        this.selectedVideoData = newSelectedVideoData;
        if (this.selectedVideo) {
          URL.revokeObjectURL(this.selectedVideoBlob);
          this.selectedVideo = '';
        }
      }
      const videoIndex = this.videos.indexOf(newSelectedVideoData);
      if (videoIndex === -1) {
        this.fetchError = this.$t('transitStore.errorFetchVideo');
        this.isFetchingVideo = false;
        this.tryReloadVideoTimerId = null;
        return;
      }
      try {
        this.selectedVideoBlob = await this.transitStore.fetchVideoInline(
          this.selectedVideoData.streamId,
          this.selectedVideoData.transitId,
        );
        this.fetchError = '';
        this.selectedVideo = URL.createObjectURL(this.selectedVideoBlob);
        this.isFetchingVideo = false;
        this.tryReloadVideoTimerId = null;
        this.videos[videoIndex].disabled = false;
      } catch (error) {
        if (error.cause === 404 || this.tryReloadVideoCounter >= 2) {
          this.emitter.emit('show-toast', { toastType: 'error', toastText: error });
          this.fetchError = error.message;
          this.videos[videoIndex].disabled = true;
          this.tryReloadVideoCounter = 0;
          this.tryReloadVideoTimerId = null;
          this.isFetchingVideo = false;
        } else {
          this.tryReloadVideoTimerId = setTimeout(() => this.changeSelectedVideo(newSelectedVideoData), 2000);
          this.tryReloadVideoCounter += 1;
          this.videos[videoIndex].disabled = true;
        }
      }
    },
  },
  watch: {
    show: 'takeFocusIfShown',
  },
  unmounted() {
    if (this.selectedVideo) {
      URL.revokeObjectURL(this.selectedVideoBlob);
    }
    if (this.tryReloadVideoTimerId) {
      clearTimeout(this.tryReloadVideoTimerId);
    }
    this.tryReloadVideoTimerId = null;
    this.initialized = false;
    this.isFetchingVideo = false;
  },
};
</script>
