<template>
  <div class="relative w-2/3">
    <p class="text-xl absolute top-[-0.6rem] left-2 bg-white px-6 border-3 shadow
      border-[#1e3c53] rounded text-gray-700 capitalize">
      {{ $t('facilityTab.facility') }}
    </p>
    <div class="w-full min-h-[100px] bg-gray-100 mx-auto my-auto rounded-2xl flex flex-col
      justify-center items-center place-items-center place-content-center py-6 gap-3 px-4">
      <SearchBar v-show="this.transitStore.facilities?.length || Object.keys(this.facilityFilter).length"
        :restoreFromSessionStorage="false"
        :placeholder="this.$t('facilityInfoView.filterByFacilityName')"
        @update-fetch-params="onFilterUpdated"/>
      <FacilityInfoViewCard v-for="facility in this.facilities" v-bind:key="facility.id"
        :currentFacilityInfo="facility"
        @facility-updated="updateFacilitiesInfo" />
      <FacilityInfoViewCard
        v-if="this.isAddingFacility"
        :isNewFacility="this.isAddingFacility"
        @facility-updated="updateFacilitiesInfo" />
      <AddSomethingButton v-if="!isAddingFacility && !currentFacilityInfo"
      :buttonText="this.$t('facilityInfoView.addFacility')"
      @button-clicked="addFacility()"/>
    </div>
  </div>
</template>

<script>
import AddSomethingButton from '@/components/generic/AddSomethingButton.vue';
import FacilityInfoViewCard from '@/components/facilityTabComponents/FacilityInfoViewCard.vue';
import SearchBar from '@/components/SearchBar.vue';
import useTransitStore from '@/stores/TransitStore';

export default {
  name: 'FacilityInfoView',
  props: ['currentFacilityInfo'],
  components: {
    AddSomethingButton,
    FacilityInfoViewCard,
    SearchBar,
  },
  data() {
    return {
      isAddingFacility: false,
      facilityFilter: {},
      facilities: [],
    };
  },
  setup() {
    const transitStore = useTransitStore();
    return {
      transitStore,
    };
  },
  methods: {
    addFacility() {
      this.isAddingFacility = true;
    },
    async updateFacilitiesInfo() {
      this.isAddingFacility = false;
      await this.transitStore.fetchFacilities();
      this.facilities = this.transitStore.facilities;
      this.setFacilitiesArray();
    },
    async onFilterUpdated(facilityFilter) {
      this.facilityFilter = { ...facilityFilter };
      this.setFacilitiesArray();
    },
    async setFacilitiesArray() {
      this.facilities = [];
      if (this.facilityFilter) {
        this.facilities = await this.transitStore.fetchFilteredFacilities(this.facilityFilter);
      } else {
        this.facilities = this.transitStore.facilities;
      }
      this.$emit('filtered-facilities', this.facilities);
    },
  },
  mounted() {
    this.updateFacilitiesInfo();
  },
};
</script>
