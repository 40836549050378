import greenTick from '@/assets/img/green-tick.png';
import amberTick from '@/assets/img/amber-tick.png';
import redTick from '@/assets/img/red-tick.png';
import truckIcon from '@/assets/img/truck.png';
import carIcon from '@/assets/img/car.png';
import motoIcon from '@/assets/img/moto.png';
import busIcon from '@/assets/img/bus.png';
import containerIcon from '@/assets/img/container.png';
import licensePlateIcon from '@/assets/img/license-plate.png';
import trailerLicensePlateIcon from '@/assets/img/trailer-license-plate.png';
import brandIcon from '@/assets/img/brand.png';
import adrIcon from '@/assets/img/adr.png';
import wagonIcon from '@/assets/img/wagon.png';
import locomotiveIcon from '@/assets/img/locomotive.png';
import questionMarkIcon from '@/assets/img/question-mark.png';
import pictureIcon from '@/assets/img/picture.png';
import downloadPictureIcon from '@/assets/img/picture_download.png';
import checkboxChecked from '@/assets/img/checkbox_checked.png';
import checkboxUnchecked from '@/assets/img/checkbox_unchecked.png';
import radioButton from '@/assets/img/radiobutton.png';
import radioButtonSelected from '@/assets/img/radiobutton_selected.png';
import roadIcon from '@/assets/img/road.png';
import craneIcon from '@/assets/img/crane.png';
import trainIcon from '@/assets/img/train.png';
import filterIcon from '@/assets/img/filter.png';
import locationIcon from '@/assets/img/location.png';
import transitIcon from '@/assets/img/transit.png';
import vehicleClassificationIcon from '@/assets/img/vehicle-classification.png';
import closeButtonIcon from '@/assets/img/close-button.png';
import flagIcon from '@/assets/img/flag.png';
import infoIcon from '@/assets/img/info.png';
import warnIcon from '@/assets/img/warn.png';
import errorIcon from '@/assets/img/error.png';
import alertIcon from '@/assets/img/alert.png';
import csvReportIcon from '@/assets/img/report_csv.png';
import xlsxReportIcon from '@/assets/img/report_xlsx.png';
import loggedInIcon from '@/assets/img/loggedIn.png';
import loadingGif from '@/assets/gif/waiting.gif';
import applicationLogo from '@/assets/img/logo.png';
import camIcon from '@/assets/img/cam.png';
import facilityIcon from '@/assets/img/facility.png';
import adminWarn from '@/assets/img/admin_warn.png';
import passwordResetIcon from '@/assets/img/password-reset.png';
import kebabIcon from '@/assets/img/kebab.png';
import locationPlusIcon from '@/assets/img/location_plus.png';
import deleteIcon from '@/assets/img/delete_bin.png';
import deleteIconDisabled from '@/assets/img/delete_bin_disabled.png';
import crossIcon from '@/assets/img/cross.png';
import containerTareIcon from '@/assets/img/container_tare.png';
import downloadVideoIcon from '@/assets/img/download_video.png';
import downloadVideoIconDisabled from '@/assets/img/download_video_disabled.png';
import imageSelectedIcon from '@/assets/img/image_selected.png';
import imageUnselectedIcon from '@/assets/img/image_unselected.png';

import { TRUCK_TYPES, CAR_TYPES, VEHICLE_INFO_TYPES } from './types';

function getVehicleTypeIcon(classificationValue) {
  const noUnderscoreValue = classificationValue.replaceAll('_', ' ');
  if (TRUCK_TYPES.includes(noUnderscoreValue)) return 'truckIcon';
  if (CAR_TYPES.includes(noUnderscoreValue)) return 'carIcon';
  if (noUnderscoreValue === 'motorcycle') return 'motoIcon';
  if (noUnderscoreValue === 'bus') return 'busIcon';
  return 'questionMark';
}

export function getIconName(iconType, classificationValue = null) {
  if (iconType === 'vehicleclassification'
    || iconType === 'vehicledetection') {
    return getVehicleTypeIcon(classificationValue);
  }
  if (VEHICLE_INFO_TYPES.includes(iconType)) return 'brandIcon';
  if (iconType === 'license plate') return 'licensePlateIcon';
  if (iconType === 'trailer license plate') return 'trailerLicensePlateIcon';
  if (iconType === 'BIC') return 'containerIcon';
  if (iconType === 'adr') return 'adrIcon';
  if (iconType === 'universalwagon') return 'wagonIcon';
  if (iconType === 'road') return 'roadIcon';
  if (iconType === 'train') return 'trainIcon';
  if (iconType === 'crane') return 'craneIcon';
  if (iconType === 'containertare') return 'containerTareIcon';
  return 'questionMark';
}

const iconMap = new Map();
iconMap.set('truckIcon', truckIcon);
iconMap.set('carIcon', carIcon);
iconMap.set('motoIcon', motoIcon);
iconMap.set('busIcon', busIcon);
iconMap.set('containerIcon', containerIcon);
iconMap.set('licensePlateIcon', licensePlateIcon);
iconMap.set('trailerLicensePlateIcon', trailerLicensePlateIcon);
iconMap.set('brandIcon', brandIcon);
iconMap.set('adrIcon', adrIcon);
iconMap.set('wagonIcon', wagonIcon);
iconMap.set('locomotiveIcon', locomotiveIcon);
iconMap.set('pictureIcon', pictureIcon);
iconMap.set('downloadPictureIcon', downloadPictureIcon);
iconMap.set('checkboxChecked', checkboxChecked);
iconMap.set('checkboxUnchecked', checkboxUnchecked);
iconMap.set('radioButton', radioButton);
iconMap.set('radioButtonSelected', radioButtonSelected);
iconMap.set('roadIcon', roadIcon);
iconMap.set('craneIcon', craneIcon);
iconMap.set('trainIcon', trainIcon);
iconMap.set('filterIcon', filterIcon);
iconMap.set('locationIcon', locationIcon);
iconMap.set('transitIcon', transitIcon);
iconMap.set('vehicleClassificationIcon', vehicleClassificationIcon);
iconMap.set('closeButtonIcon', closeButtonIcon);
iconMap.set('flagIcon', flagIcon);
iconMap.set('infoIcon', infoIcon);
iconMap.set('warnIcon', warnIcon);
iconMap.set('errorIcon', errorIcon);
iconMap.set('greenTick', greenTick);
iconMap.set('amberTick', amberTick);
iconMap.set('redTick', redTick);
iconMap.set('alertIcon', alertIcon);
iconMap.set('csvReportIcon', csvReportIcon);
iconMap.set('xlsxReportIcon', xlsxReportIcon);
iconMap.set('loggedInIcon', loggedInIcon);
iconMap.set('loadingGif', loadingGif);
iconMap.set('applicationLogo', applicationLogo);
iconMap.set('camIcon', camIcon);
iconMap.set('facilityIcon', facilityIcon);
iconMap.set('adminWarn', adminWarn);
iconMap.set('passwordResetIcon', passwordResetIcon);
iconMap.set('locationPlusIcon', locationPlusIcon);
iconMap.set('kebabIcon', kebabIcon);
iconMap.set('deleteIcon', deleteIcon);
iconMap.set('deleteIconDisabled', deleteIconDisabled);
iconMap.set('crossIcon', crossIcon);
iconMap.set('containerTareIcon', containerTareIcon);
iconMap.set('downloadVideoIcon', downloadVideoIcon);
iconMap.set('downloadVideoIconDisabled', downloadVideoIconDisabled);
iconMap.set('imageSelectedIcon', imageSelectedIcon);
iconMap.set('imageUnselectedIcon', imageUnselectedIcon);

export function getIcon(iconName) {
  if (!iconMap.has(iconName)) {
    return questionMarkIcon;
  }
  return iconMap.get(iconName);
}

export function getConfidenceIcon(confidenceValue) {
  const confidence = confidenceValue ?? 0;
  if (confidence >= 0.85) {
    return getIcon('greenTick');
  }
  if (confidence >= 0.6) {
    return getIcon('amberTick');
  }
  return getIcon('redTick');
}
