<template>
  <ResetPassword
    :show="this.showResetPassword"
    :singleUsePassword="this.password"/>
  <div class="min-h-screen flex items-center justify-center bg-[#1e3c53]">
    <div class="max-w-lg w-full rounded-3xl bg-gray-50 border-gray-300 p-6">
      <img class="h-12 w-auto" :src= this.appLogo alt="AllRead logo">
      <h2 class="mt-2 text-center text-2xl font-extrabold text-gray-900 cursor-default">
        {{ $t('loginPage.signIn') }}
      </h2>
      <Form action="#"
         method="POST" @submit="submitForm"
          :validation-schema="schemaValidation">
        <input type="hidden" name="remember" value="true">
        <div class="flex flex-col gap-2 mb-5 mt-4">
          <div v-if="this.authMethod === 'active_directory'">
            <label htmlFor="username">
              <Field :validateOnBlur="true"
                :validateOnChange="true" :validateOnInput="true"
                id="username" name="username" v-model="username" :showEmail="false"
                  class="appearance-none relative block w-full px-3 py-2
                  border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md
                  focus:outline-none focus:ring-sky-500 focus:border-sky-500 focus:z-10
                  sm:text-sm focus:shadow-inner" :placeholder="$t('loginPage.userName')" />
            </label>
            <ErrorMessage name="username" class="invalid-feedback" />
          </div>
          <div v-else>
            <label htmlFor="email">
              <Field :validateOnBlur="true"
                :validateOnChange="false" :validateOnInput="true"
                id="email" name="email" v-model="email" :showEmail="true"
                  type="email" required autocomplete="email"
                  class="appearance-none relative block w-full px-3 py-2
                  border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md
                  focus:outline-none focus:ring-sky-500 focus:border-sky-500 focus:z-10
                  sm:text-sm focus:shadow-inner" :placeholder="$t('loginPage.emailAdress')" />
            </label>
            <ErrorMessage name="email" class="invalid-feedback" />
          </div>
          <div class="relative">
            <label htmlFor="current-password">
              <Field :validateOnBlur="true"
                :validateOnChange="false" :validateOnInput="true"
                id="current-password" name="current-password" v-model="password"
                :type="showPassword ? 'text' : 'password'"
                  required
                  autocomplete="current-password"
                  class="appearance-none relative block w-full px-3
                  py-2 border border-gray-300 placeholder-gray-500 text-gray-900
                  rounded-md focus:outline-none focus:ring-sky-500 focus:border-sky-500
                  focus:z-10 sm:text-sm focus:shadow-inner" :placeholder="$t('loginPage.password')" />
            </label>
            <ErrorMessage name="current-password" class="invalid-feedback" />
            <!-- Vue.js @click event handler already handle
            the keyboard listener and the linter doesn't catch that-->
            <!-- eslint-disable-next-line -->
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer z-10"
              @click="showPassword = !showPassword">
              <img :src="showPassword ? hideEyeImage : showEyeImage"
              :alt="$t('loginPage.togglePasswordVisibility')"
              :title="$t('loginPage.togglePasswordVisibility')"
              class="w-5 h-5">
            </div>
          </div>
          <p class="flex justify-center text-center text-red-800" v-show="this.isLoginError">
            {{ $t('loginPage.loginFailedError',
            { what: this.authMethod === 'active_directory' ? $t('loginPage.userName') : $t('loginPage.email') }) }}
          </p>
        </div>
        <div class="space-y-2">
            <div class="flex flex-row align-middle items-center gap-2">
              <div>
              <label class="border-none m-0 p-0 h-0" for="selector">
              <select v-show="this.arrayMethods.length > 1" ref="selector" class="group form-select block border
              border-gray-300 text-xs rounded-md custom-selector text-gray-900 bg-gray-100
              hover:bg-gray-200 focus:outline-none focus:ring-sky-500"
              @change="onAuthChange($event)">
                <option v-for="authType in this.arrayMethods" :key="authType.type"
                class="text-xs custom-selector-option" :value='authType.type'>
                  {{ authType.name }}
                </option>
              </select>
              </label>
              </div>
              <button type="submit" class="group relative w-full flex justify-center py-2
              text-sm font-medium rounded-md text-white object-contain
              bg-sky-500 hover:bg-sky-700 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-sky-500 disabled:bg-[#83878a]"
              :disabled="areThereEmptyFields()">
              {{ $t('loginPage.signIn') }}
              </button>
            </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import {
  Form, Field, ErrorMessage,
} from 'vee-validate';
import * as yup from 'yup';
import useTransitStore from '@/stores/TransitStore';
import useUserManagementStore from '@/stores/UserManagementStore';
import showEyeImage from '@/assets/img/show-eye.png';
import hideEyeImage from '@/assets/img/hide-eye.png';
import { getAuthMethodTypes } from '@/utils/auth';
import { thereIsClientLogo, authMethodsIndexes } from '@/../TransitViewerConfig';
import { getIcon } from '@/utils/icons';
import ResetPassword from '@/components/ResetPassword.vue';

export default {
  name: 'LoginPage',
  components: {
    Form,
    Field,
    ErrorMessage,
    ResetPassword,
  },
  created() {
    document.title = this.$t('loginPage.documentTitle');
  },
  setup() {
    const transitStore = useTransitStore();
    const userManagementStore = useUserManagementStore();
    return {
      transitStore,
      userManagementStore,
    };
  },
  data() {
    return {
      username: '',
      email: '',
      password: '',
      showPassword: false,
      showEmail: true,
      showEyeImage,
      hideEyeImage,
      authMethod: 'allread',
      isLoginError: false,
      arrayMethods: [],
      appLogo: getIcon('applicationLogo'),
      schemaValidation: yup.object({
        showEmail: yup.boolean(),
        email: yup
          .string()
          .email(this.$t('loginPage.enterValidEmail'))
          .when('showEmail', {
            is: true,
            then: yup.string().required(),
          }),
      }),
      showResetPassword: false,
    };
  },
  methods: {
    onAuthChange(event) {
      this.authMethod = event.target.value;
    },
    async submitForm() {
      this.isLoginError = false;
      try {
        if ((this.email || this.username) && this.password) {
          const authUser = this.authMethod === 'active_directory' ? this.username : this.email;
          await this.userManagementStore.handleSignIn(authUser, this.password, this.authMethod);
          localStorage.dashboardAuthMethod = JSON.stringify(this.authMethod);
          if (this.userManagementStore.isUserReset()) {
            this.showResetPassword = true;
          } else {
            this.$router.push({ path: '/' });
          }
        }
      } catch (err) {
        console.error(err);
        this.isLoginError = true;
      }
    },
    restoreAuthMethod() {
      if (localStorage.dashboardAuthMethod) {
        this.authMethod = JSON.parse(localStorage.dashboardAuthMethod);
      }
      this.$refs.selector.value = this.authMethod;
    },
    areThereEmptyFields() {
      if (this.authMethod === 'active_directory') {
        return this.username === '' || this.password === '';
      }
      return this.email === '' || this.password === '';
    },
  },
  async mounted() {
    this.restoreAuthMethod();
    this.arrayMethods = getAuthMethodTypes(authMethodsIndexes());
    if (thereIsClientLogo()) {
      const clientLogo = await this.transitStore.fetchClientLogo();
      if (clientLogo) {
        this.appLogo = clientLogo;
      }
    }
    if (this.userManagementStore.isSignedIn() && this.userManagementStore.isUserReset()) {
      this.showResetPassword = true;
    }
  },
};
</script>
