<template>
  <div class="bg-white rounded-2xl px-5 py-3 w-full shadow relative">
    <IconDropdownMenu
      v-if="this.currentFacilityInfo && this.userManagementStore.hasFacilitiesWritePermission()"
      class="absolute top-2 end-2 min-w-max h-14"
      :comboOptions="this.dropdownOptions"
      :notShowIfSelected=false
      buttonIcon="kebabIcon"
      @update:selection-changed="showDeleteFacilityWarning"/>
    <div class="absolute top-5 left-16 flex flex-col gap-2 align-center items-center">
      <i class="min-w-max h-[70px]">
        <img
          :src="getIconImage('facilityIcon')"
          class="w-[70px] h-[70px] px-1 opacity-95 border border-gray-100 rounded shadow-md"
          alt="transit type icon">
        <img v-if="this.warningMessage" :src="getIconImage('adminWarn')" class="w-8 h-8 absolute -top-3 left-7"
        alt="warning icon" :title="this.warningMessage">
      </i>
      <div>
        <p class="text-xs">
          {{ this.$t('facilityInfoViewCard.created') }} {{ getLocalDateTimeFromTimestamp(this.currentFacilityInfo?.created_at) }}
        </p>
        <p class="text-xs">
          {{ this.$t('facilityInfoViewCard.updated') }} <b>{{ getLocalDateTimeFromTimestamp(this.currentFacilityInfo?.updated_at) }}</b>
        </p>
      </div>
    </div>
    <div class="flex flex-col items-centered justify-center">
      <div class="grid grid-cols-2 gap-1">
        <p class="place-self-end p-1 self-center">{{ $t('facilityInfoViewCard.facilityAlias') }}:</p>
        <EditableField
          :fieldId="`facilityAlias${this.currentFacilityInfo?.id}`"
          :fieldValidator="validateAlias"
          :nonEditable="!this.userManagementStore.hasFacilitiesWritePermission()"
          :value="this.facilityInfo?.alias"
          :placeholder="this.$t('facilityInfoViewCard.facilityAliasPlaceholder')"/>
        <p class="place-self-end p-1 self-center">{{ $t('facilityInfoViewCard.facilityName') }}:</p>
        <EditableField
          :fieldId="`facilityName${this.currentFacilityInfo?.id}`"
          :fieldValidator="validateName"
          :nonEditable="!this.userManagementStore.hasFacilitiesWritePermission()"
          :value="this.facilityInfo?.name"
          :placeholder="this.$t('facilityInfoViewCard.facilityNamePlaceholder')"/>
        <p class="place-self-end p-1 self-center">{{ $t('facilityInfoViewCard.facilityAddress') }}:</p>
        <EditableField
          :fieldId="`facilityAddress${this.currentFacilityInfo?.id}`"
          :fieldValidator="validateAddress"
          :nonEditable="!this.userManagementStore.hasFacilitiesWritePermission()"
          :value="this.facilityInfo?.address"
          :placeholder="this.$t('facilityInfoViewCard.facilityAddressPlaceholder')"/>
        <p class="place-self-end p-1 self-center">{{ $t('facilityInfoViewCard.facilityDescription') }}:</p>
        <EditableField
          :fieldId="`facilityDescription${this.currentFacilityInfo?.id}`"
          :fieldValidator="validateDescription"
          :nonEditable="!this.userManagementStore.hasFacilitiesWritePermission()"
          :value="this.facilityInfo?.description"
          :placeholder="this.$t('facilityInfoViewCard.facilityDescriptionPlaceholder')"/>
        <p class="place-self-end p-1 self-center">{{ $t('facilityInfoViewCard.facilityEmail') }}:</p>
        <EditableField
          :fieldId="`facilityContactEmail${this.currentFacilityInfo?.id}`"
          :fieldValidator="validateEmail"
          :nonEditable="!this.userManagementStore.hasFacilitiesWritePermission()"
          :value="this.facilityInfo?.contact_email"
          :placeholder="this.$t('facilityInfoViewCard.facilityEmailPlaceholder')"/>
      </div>
      <hr v-if="this.errorMessage" class="border-t-1 border-dashed border-gray-300 mt-4 mb-4"/>
      <pre v-if="this.errorMessage" class="mt-2 text-red-700 text-sm">{{ this.errorMessage }}</pre>
    </div>
    <hr v-if="this.areFieldsEdited || !this.currentFacilityInfo" class="border-t-1 border-dashed border-gray-300 mt-2 mb-1"/>
    <div v-show="this.areFieldsEdited || this.isNewFacility" class="flex gap-6 justify-center">
      <button @click="cancelAddOrEditFacility"
        class="mt-2 px-4 py-2 text-gray-600 hover:font-bold underline underline-offset-4 hover:decoration-4 hover:text-rose-950">
        {{ $t('facilityInfoViewCard.cancel') }}
      </button>
      <button @click="submitFacility"
        class="mt-2 px-3 py-1 bg-sky-500 text-white rounded
          hover:bg-sky-700 transition-colors duration-300 disabled:bg-gray-300"
          :disabled="!this.submitButtonEnabled">
          {{ $t('facilityInfoViewCard.applyChangesButton') }}
      </button>
    </div>
    <ModalDialog
      ref="FacilityInfoViewCardModal"
      @delete-facility="deleteFacility"/>
  </div>
</template>

<script>

import useUserManagementStore from '@/stores/UserManagementStore';
import useTransitStore from '@/stores/TransitStore';
import EditableField from '@/components/generic/EditableField.vue';
import IconDropdownMenu from '@/components/generic/IconDropdownMenu.vue';
import ModalDialog from '@/components/generic/ModalDialog.vue';
import { getIcon } from '@/utils/icons';
import { getLocalDateTimeFromTimestamp } from '@/utils/date';
import getAliasOrNameFromObject from '@/utils/objects';

export default {
  name: 'FacilityInfoViewCardCard',
  props: ['currentFacilityInfo', 'isNewFacility'],
  components: {
    EditableField,
    IconDropdownMenu,
    ModalDialog,
  },
  setup() {
    const transitStore = useTransitStore();
    const userManagementStore = useUserManagementStore();
    return {
      getIconImage: getIcon,
      getLocalDateTimeFromTimestamp,
      transitStore,
      userManagementStore,
      getAliasOrNameFromObject,
    };
  },
  data() {
    return {
      errorMessage: '',
      errorMessages: {
        name: '',
        address: '',
        contactEmail: '',
      },
      warningMessage: '',
      facilityInfo: {},
      areFieldsEdited: this.isNewFacility,
      submitButtonEnabled: false,
      dropdownOptions: [
        {
          id: 'delete-facility',
          name: this.$t('facilityInfoViewCard.deleteFacilityMenuText'),
          iconName: 'deleteIcon',
          buttonItemTextStyle: 'text-red-800',
        },
      ],
    };
  },
  methods: {
    validateAlias(editedAlias) {
      this.facilityInfo.alias = editedAlias;
      this.joinValidations();
    },
    validateName(editedName) {
      this.errorMessages.name = (editedName?.length < 3) ? this.$t('facilityInfoViewCard.nameMinimumLenght') : '';
      this.facilityInfo.name = editedName;
      this.joinValidations();
    },
    validateAddress(editedAddress) {
      this.errorMessages.address = (editedAddress?.length < 1) ? this.$t('facilityInfoViewCard.addressMinimumLenght') : '';
      this.facilityInfo.address = editedAddress;
      this.joinValidations();
    },
    validateEmail(editedEmail) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.errorMessages.contactEmail = (editedEmail?.length > 50
                                      || !emailPattern.test(editedEmail)) ? this.$t('facilityInfoViewCard.enterValidEmail') : '';
      this.facilityInfo.contact_email = editedEmail;
      this.joinValidations();
    },
    validateDescription(editedDescription) {
      this.facilityInfo.description = editedDescription;
      this.joinValidations();
    },
    joinValidations() {
      this.errorMessage = Object.values(this.errorMessages).filter(Boolean).join('\n');
      this.areFieldsEdited = (JSON.stringify(this.currentFacilityInfo) !== JSON.stringify(this.facilityInfo));
      this.submitButtonEnabled = !this.errorMessage
      && (JSON.stringify(this.currentFacilityInfo) !== JSON.stringify(this.facilityInfo))
      && this.facilityInfo.name
      && this.facilityInfo.address
      && this.facilityInfo.contact_email;
    },
    async submitFacility() {
      const editedFacility = Number.isInteger(this.currentFacilityInfo?.id);
      try {
        if (editedFacility) {
          await this.transitStore.editFacility(this.facilityInfo);
        } else {
          await this.transitStore.addFacility(this.facilityInfo);
        }
        this.emitter.emit('show-toast', {
          toastType: 'info',
          toastText: this.$t(
            'facilityTab.facilityAddedSuccessfully',
            {
              facilityName: getAliasOrNameFromObject(this.facilityInfo),
              what: editedFacility ? this.$t('facilityTab.edited') : this.$t('facilityTab.added'),
            },
          ),
        });
        this.$emit('facility-updated');
      } catch (error) {
        console.error('Error adding facility', error);
        this.emitter.emit('show-toast', {
          toastType: 'error',
          toastText: this.$t('facilityTab.errorAddingFacility', { facilityName: getAliasOrNameFromObject(this.facilityInfo) }),
        });
      }
    },
    cancelAddOrEditFacility() {
      this.areFieldsEdited = false;
      this.submitButtonEnabled = false;
      this.facilityInfo = { ...this.currentFacilityInfo };
      this.errorMessage = '';
      this.checkWarnings();
      this.$emit('facility-updated');
    },
    async checkWarnings() {
      await this.transitStore.fetchLocation();
      this.warningMessage = '';
      if (!this.transitStore.locations?.some((location) => location.facility_id === this.currentFacilityInfo?.id)) {
        this.warningMessage = this.$t('facilityInfoViewCard.noLocationsConfigured');
      }
    },
    showDeleteFacilityWarning() {
      const modalInfo = {
        modalTitle: this.$t('facilityInfoViewCard.modalDeleteFacilityTitle'),
        modalText: this.$t('facilityInfoViewCard.modalDeleteFacilityText', { who: getAliasOrNameFromObject(this.facilityInfo) }),
        modalQuestion: this.$t('facilityInfoViewCard.modalDeleteFacilityQuestion'),
        showCancelButton: true,
        signalOnAccept: 'delete-facility',
      };
      this.$refs.FacilityInfoViewCardModal.openModal(modalInfo);
    },
    async deleteFacility() {
      if (await this.transitStore.deleteFacility(this.currentFacilityInfo)) {
        this.emitter.emit('show-toast', {
          toastType: 'info',
          toastText: this.$t('facilityInfoViewCard.sourceDeletedSuccessfully', { who: getAliasOrNameFromObject(this.facilityInfo) }),
        });
        this.$emit('facility-updated');
      } else {
        this.emitter.emit('show-toast', {
          toastType: 'error',
          toastText: this.$t('facilityInfoViewCard.sourceDeleteFailed', { who: getAliasOrNameFromObject(this.facilityInfo) }),
        });
      }
    },
  },
  beforeMount() {
    this.facilityInfo = { ...this.currentFacilityInfo };
  },
  async mounted() {
    this.emitter.on('updated_facilities', this.checkWarnings);
    this.emitter.on('updated_locations', this.checkWarnings);
    this.submitButtonEnabled = false;
    this.areFieldsEdited = false;
    this.checkWarnings();
  },
};
</script>
