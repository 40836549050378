<template>
  <SingleSelectCombo
    ref="singleSelectCombo"
    :buttonText="this.buttonText"
    :uniqueComboName="this.uniqueComboName"
    :comboOptions="this.userRolesArray"
    :restoreFromSessionStorage="false"
    :disabled="this.nonEditable"
    @comboSelectionChanged="userRoleSelectorUpdated"/>
</template>

<script>
import SingleSelectCombo from '@/components/generic/SingleSelectCombo.vue';
import useUserManagementStore from '@/stores/UserManagementStore';

export default {
  name: 'UserRoleSelector',
  components: {
    SingleSelectCombo,
  },
  setup() {
    const userManagement = useUserManagementStore();
    return {
      userManagement,
    };
  },
  data() {
    const selectedOption = { id: '', value: '', name: '' };
    return {
      userRolesArray: this.userManagement.roles,
      selectedOption,
      buttonText: this.$t('userRoleSelector.selectUserRole'),
    };
  },
  props: {
    uniqueComboName: {
      type: String,
      required: true,
    },
    nonEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    userRoleSelectorUpdated(option) {
      this.selectedOption = { ...option };
      this.buttonText = this.getButtonText();
      this.$emit('update:roleSelectorChanged', this.selectedOption.id);
    },
    getButtonText() {
      const buttonTextValue = this.selectedOption
        ? this.$t(`userRoleSelector.${this.selectedOption.name}`)
        : this.$t('userRoleSelector.selectUserRole');
      return buttonTextValue;
    },
    setPreselectedOptionId(preselectedOptionId) {
      this.userRolesArray.forEach((userRole) => {
        if (userRole.id === preselectedOptionId) {
          this.selectedOption = { ...userRole };
          this.buttonText = this.getButtonText();
          this.$refs.singleSelectCombo.setPreselectedOption(this.selectedOption);
        }
      });
    },
  },
};
</script>
