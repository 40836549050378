function isValidUrl(url) {
  try {
    const parsedUrl = new URL(url);
    return ['rtsp:'].includes(parsedUrl.protocol);
  } catch (e) {
    return false;
  }
}

export default isValidUrl;
