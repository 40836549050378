<template>
  <label :htmlFor="this.fieldId">
    <textarea cols="30"
      rows="1"
      :id="this.fieldId"
      ref="textAreaElement"
      v-model="this.fieldValue"
      v-on:keyup="fieldEdited"
      v-on:blur="fieldEdited"
      :placeholder="this.placeholder"
      autocomplete="off"
      spellcheck="false"
      :class="['flex bg-transparent font-semibold overflow-hidden resize-none align-text-bottom',
        this.nonEditable ? 'cursor-default outline-none' : 'cursor-pointer hover:bg-gray-200 focus:font-normal focus:bg-white',
        'rounded-md p-1 place-self-start',
        this.fieldValue ? '' : 'min-w-[100px] bg-white border-2 border-gray-300 h-8'
      ]"></textarea>
  </label>
</template>

<script>
export default {
  name: 'EditableField',
  props: {
    fieldId: {
      type: String,
      required: true,
    },
    fieldValidator: {
      required: false,
      type: Function,
    },
    value: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    nonEditable: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['editing-field', 'finished-editing'],
  data() {
    return {
      fieldValue: this.value,
    };
  },
  methods: {
    preventReturn(event) {
      const keyCode = (event.keyCode ? event.keyCode : event.which);
      if (keyCode === 13) {
        event.preventDefault();
        event.target.blur();
      }
    },
    switchEditableComponentIfNecessary() {
      this.$refs.textAreaElement.style.display = 'block';
      this.$refs.textAreaElement.style.height = 'auto';
      this.$refs.textAreaElement.style.height = `${this.$refs.textAreaElement.scrollHeight}px`;
    },
    fieldEdited(event) {
      const keyCode = (event.keyCode ? event.keyCode : event.which);
      if (keyCode === 13 && !event.shiftKey) {
        this.removeCharFromFieldValueAt(this.$refs.textAreaElement.selectionStart);
        event.preventDefault();
        event.target.blur();
      } else if (this.fieldValidator) {
        this.fieldValidator(this.fieldValue);
      }
    },
    updateFieldValue() {
      this.fieldValue = this.value;
      this.switchEditableComponentIfNecessary();
    },
    removeCharFromFieldValueAt(pos) {
      const tmpArray = this.fieldValue.split('');
      tmpArray.splice(pos - 1, 1);
      this.fieldValue = tmpArray.join('');
    },
  },
  async mounted() {
    this.updateFieldValue();
  },
  watch: {
    value: 'updateFieldValue',
  },
};
</script>
