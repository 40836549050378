<template>
  <div class="relative" @focusin="hasFocus=true" @focusout="hasFocus=false">
    <div class="inline-flex items-center">
      <button ref="titleButton" :class="[
        'flex h-8 items-center border bg-white hover:bg-gray-100 inline-block break-keep min-w-fit',
        'py-1 rounded-lg pr-8 relative',
        (!leftButtonIconName) ? 'pl-2' : '']"
        @click="toggleDropdown">
          <img v-if="leftButtonIconName !== ''"
            :src="getComboIcon(leftButtonIconName)"
            :class="['mx-1 w-5 h-5',
            leftButtonIconStyleInjection,
            ]"
            alt="multi-select-combo icon"/>
        {{ buttonText }}
        <svg class="absolute ml-1 right-2" width="20px" height="20px" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path v-if="dropdownHidden" fill-rule="evenodd" clip-rule="evenodd" d="M12.7071 14.7071C12.3166 15.0976 11.6834 15.0976
                11.2929 14.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237
                7.31658 7.90237 7.70711 8.29289L12 12.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071
                8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L12.7071 14.7071Z" fill="#000000" />
          <path v-else fill-rule="evenodd" clip-rule="evenodd" d="m 12.7071,8.292875 c -0.3905,-0.3905 -1.0237,-0.3905 -1.4142,0 l
                -5.00001,4.99999 c -0.39052,0.39053 -0.39052,1.02369 0,1.41422 0.39053,0.39052 1.02369,0.39052 1.41422,0 L 12,10.414175
                l 4.2929,4.29291 c 0.3905,0.39052 1.0237,0.39052 1.4142,0 0.3905,-0.39053 0.3905,-1.02369 0,-1.41422 z"
            fill="#000000" />
        </svg>
      </button>
      <div ref="dropdown" v-show="!dropdownHidden"
        :class="['absolute top-9 rounded-md shadow-lg bg-white cursor-pointer z-40',
        this.comboOptions.length > 10 ? ' right-0 overflow-y-scroll max-h-[450px]' : '']">
        <div v-if="showAllNoneOptions">
          <div class="flex justify-center gap-3 text-sm bg-gray-50 rounded-t-md">
            <button @click="selectAll" class="hover:underline hover:font-medium">{{ this.$t('multiSelectCombo.all') }}</button>
            {{' / '}}
            <button @click="selectNone" class="hover:underline hover:font-medium">{{ this.$t('multiSelectCombo.none')}}</button>
          </div>
          <hr class="border-t-1 border-dashed border-gray-300 mx-2"/>
        </div>
        <div v-for="optionValue in this.comboOptions" :key="optionValue.id">
          <button
            :class="['flex items-center text-left text-gray-700',
                  this.capitalize ? 'capitalize' : '',
                  'hover:bg-gray-100 hover:rounded-md hover:text-gray-900',
                  'w-full p-2 overflow-hidden truncate hover:overflow-auto']"
            @click="optionToggled(optionValue.id)">
            <i class="w-5 h-5"><img :src="getComboIcon(comboOptionIds.includes(optionValue.id) ? 'checkboxChecked' : 'checkboxUnchecked')"
              class="w-5 h-5 ml-1 mr-2" alt="checkbox icon" /></i>
            <span class="pl-3">{{ optionValue.value.replaceAll('_', ' ') }}</span>
          </button>
        </div>
        <hr class="border-t border-gray-300 mx-2" />
        <div>
          <button @click="applyAndCloseDropdown" class="m-1 px-4 py-1 bg-sky-500 text-white text-xs rounded
                      hover:bg-sky-700 transition-colors duration-300 my-2 disabled:bg-gray-200"
            :disabled="comboOptionIds.length === 0">
            {{ this.$t('multiSelectCombo.apply') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getIcon } from '@/utils/icons';

export default {
  name: 'MultiSelectCombo',
  props: {
    uniqueComboName: {
      type: String,
      required: true,
    },
    comboOptions: {
      type: Object,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    leftButtonIconName: {
      type: String,
      default: '',
      required: false,
    },
    leftButtonIconStyleInjection: {
      type: String,
      required: false,
    },
    signalOnChange: {
      type: String,
      required: true,
    },
    showAllNoneOptions: {
      type: Boolean,
      required: false,
    },
    capitalize: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    const getComboIcon = getIcon;
    return {
      dropdownHidden: true,
      comboOptionIds: [],
      getComboIcon,
      hasFocus: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.emitter.emit('close-other-combos', this);
      this.dropdownHidden = !this.dropdownHidden;
    },
    closeDropdown() {
      this.dropdownHidden = true;
    },
    applyAndCloseDropdown() {
      this.closeDropdown();
      sessionStorage.setItem(this.uniqueComboName, JSON.stringify(this.comboOptionIds));
      this.$emit(this.signalOnChange, this.comboOptionIds);
    },
    optionToggled(optionId) {
      const optionIdIndex = this.comboOptionIds.indexOf(optionId);
      if (optionIdIndex === -1) {
        this.comboOptionIds.push(optionId);
      } else {
        this.comboOptionIds.splice(optionIdIndex, 1);
      }
      this.dropdownHidden = false;
    },
    switchOptionsToggle(optionIds) {
      Object.values(optionIds).forEach((optionId) => {
        this.optionToggled(optionId);
      });
      this.applyAndCloseDropdown();
      this.emitter.emit('switch-option-toggled');
    },
    restoreSessionData() {
      const comboSession = sessionStorage.getItem(this.uniqueComboName);
      this.comboOptionIds = [];
      if (comboSession?.length > 0) {
        JSON.parse(comboSession).forEach((selectedId) => this.comboOptionIds.push(selectedId));
      } else {
        Object.values(this.comboOptions).forEach((comboOption) => this.comboOptionIds.push(comboOption.id));
        sessionStorage.setItem(this.uniqueComboName, JSON.stringify(this.comboOptionIds));
      }
    },
    selectNone() {
      this.comboOptionIds = [];
      sessionStorage.setItem(this.uniqueComboName, JSON.stringify(this.comboOptionIds));
      this.$emit(this.signalOnChange, this.comboOptionIds);
    },
    selectAll() {
      this.comboOptionIds = [];
      Object.values(this.comboOptions).forEach((comboOption) => {
        this.comboOptionIds.push(comboOption.id);
      });
      sessionStorage.setItem(this.uniqueComboName, JSON.stringify(this.comboOptionIds));
      this.$emit(this.signalOnChange, this.comboOptionIds);
    },
  },
  mounted() {
    this.emitter.on('close-other-combos', (emitterCombo) => {
      if (emitterCombo !== this) {
        this.closeDropdown();
      }
    });
  },
  unmounted() {
    this.emitter.off('close-other-combos');
  },
  watch: {
    comboOptions: 'restoreSessionData',
  },
};
</script>
