<template>
  <div class="flex flex-col gap-5 bg-[#1e3c53] rounded-xl mb-5 pb-5">
    <div class="flex justify-center">
      <button @click="showFilter = !showFilter"
        class="bg-sky-500 text-white rounded-full hover:bg-sky-700
        transition-colors duration-300 w-9 h-9 p-1 mr-3 mt-3 mb-3 hover:scale-105"
        :title="$t('statsTab.showStatsFilter')">
        <img :src="getIconImage('filterIcon')" alt="filter icon">
      </button>
      <div v-for="statLookup, statKey in this.transitStore.statsTypes" :key="statKey">
        <button v-if="!statLookup.hidden"
          :class="['rounded-full',
          getStyleForStatTypeButton(statKey),
          'transition-colors duration-300 w-8 h-8 p-1 mr-3 mt-3 mb-3 flex flex-col items-center ml-5',
          ]"
          @click="onStatTypeSelected(statKey)"
          :disabled="isTheLastSelected(statKey)"
          :title="$t(statLookup.name)">
          <img :src="getIconImage(statLookup.iconName)" alt="filter icon">
          <p class="text-xs pt-2 -rotate-[17deg]">{{ $t(statLookup.name) }}</p>
        </button>
      </div>
    </div>
    <hr class="border-t border-sky-300 mt-4 mb-2 mx-2"/>
    <LoadingOverlay v-if="isLoading" :show="isLoading" />
    <div v-else-if="transitStore.errors.fetchStats" class="p-4 mt-4 mx-2 border rounded
      border-red-500 text-red-500">
      {{ transitStore.errors.fetchStats }}
    </div>
    <div v-else-if="transitStore.stats.isNoContent"
    class="p-4 m-4 border rounded bg-sky-100 border-sky-500 text-sky-800">
    <p class="text-2xl font-bold">{{ $t('statsTab.noStatsToShow') }}</p>
    </div>
    <div v-else v-for="(element, key) in this.transitStore.stats.elements" :key="key" class="flex gap-3">
      <StatsCard :element="element" :key="key"/>
    </div>
  </div>
  <StatsFilterPopUp
    :show="showFilter"
    @close="showFilter = false"
    @filter-updated="onFilterUpdated"
    :currentFetchParams='sessionFilters'/>
</template>

<script>
import useTransitStore from '@/stores/TransitStore';
import { getIcon } from '@/utils/icons';
import StatsCard from './StatsCard.vue';
import StatsFilterPopUp from './StatsFilterPopUp.vue';
import LoadingOverlay from './LoadingOverlay.vue';

export default {
  name: 'StatsTab',
  components: {
    StatsFilterPopUp,
    StatsCard,
    LoadingOverlay,
  },
  data() {
    return {
      getIconImage: getIcon,
      statsObject: {},
      showFilter: false,
      isLoading: true,
      sessionFilters: {},
      statTypeSwitched: [],
      statTypeSwitchTimerId: null,
    };
  },
  setup() {
    const transitStore = useTransitStore();
    return {
      transitStore,
    };
  },
  methods: {
    getStyleForStatTypeButton(statKey) {
      if (this.sessionFilters.statsTypes?.indexOf(statKey) !== -1) {
        return 'bg-sky-200 hover:bg-gray-100 text-sky-200 hover:text-sky-300 font-bold disabled:bg-[#7091a2] disabled:text-[#7091a2]';
      }
      return 'bg-gray-50 hover:bg-gray-400 text-white hover:text-sky-300';
    },
    isTheLastSelected(statKey) {
      return (this.sessionFilters.statsTypes?.length === 1
           && this.sessionFilters.statsTypes?.includes(statKey));
    },
    onStatTypeSelected(statKey) {
      const statKeyIndex = this.sessionFilters.statsTypes?.indexOf(statKey);
      if (statKeyIndex !== -1) this.sessionFilters.statsTypes.splice(statKeyIndex, 1);
      else this.sessionFilters.statsTypes.push(statKey);
      this.statTypeSwitched.push(statKey);
      if (this.statTypeSwitchTimerId) clearTimeout(this.statTypeSwitchTimerId);
      this.statTypeSwitchTimerId = setTimeout(() => this.triggerEmitStatTypesSwitched(), 800);
    },
    triggerEmitStatTypesSwitched() {
      this.emitter.emit('stats-types-switched', this.statTypeSwitched);
      this.statTypeSwitched = [];
      this.statTypeSwitchTimerId = null;
    },
    async onFilterUpdated(filters) {
      this.isLoading = true;
      this.sessionFilters = { ...filters };
      sessionStorage.statsFilter = JSON.stringify(this.sessionFilters);
      try {
        await this.transitStore.fetchStats({
          initialTime: this.sessionFilters.fromDate || 0,
          finishTime: this.sessionFilters.toDate || Math.floor(Date.now() / 1000),
          statsTypes: this.sessionFilters.statsTypes || [],
          locations: this.sessionFilters.locations || [],
          searchValue: this.sessionFilters.searchValue || '',
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
  async beforeCreate() {
    this.isLoading = true;
    try {
      await this.transitStore.fetchLocation();
      this.emitter.emit('locations-updated', this.transitStore.locations);
      await this.transitStore.fetchStatsTypes();
      this.emitter.emit('stats-types-updated', this.transitStore.statsTypes);
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }

    if (!sessionStorage.statsFilter) {
      sessionStorage.statsFilter = {};
    } else {
      this.sessionFilters = JSON.parse(sessionStorage.statsFilter);
    }
    if (!this.sessionFilters.statsTypes || this.sessionFilters.statsTypes.length === 0) {
      this.sessionFilters.statsTypes = [];
      Object.keys(this.transitStore.statsTypes).forEach((key) => this.sessionFilters.statsTypes.push(key));
    }
  },
};
</script>
