<template>
  <div class="relative w-2/3">
    <p class="text-xl absolute top-[-0.6rem] left-2 bg-white px-6 border-3 shadow
      border-[#1e3c53] rounded text-gray-700 capitalize">
      {{ $t('facilityTab.sources') }}
    </p>
    <div class="w-full min-h-[100px] bg-gray-100 mx-auto my-auto rounded-2xl flex flex-col
      justify-center items-center place-items-center place-content-center py-6 gap-3 px-4">
      <SearchBar v-show="this.sourcesInfo.length || this.filterValues.searchValue || this.filteredLocations.length === 0"
        :restoreFromSessionStorage="false"
        :placeholder="this.$t('sourceInfoView.filterBySourceName')"
        @update-fetch-params="onFilterUpdated"/>
      <SourceInfoViewCard v-for="sourceInfo in this.sourcesInfo" v-bind:key="sourceInfo.id"
      :currentSourceInfo="sourceInfo"
      :locations="this.transitStore.locations"
      @source-updated="refreshSources()" />
      <SourceInfoViewCard
      v-if="this.isAddingSource"
      :locations="this.transitStore.locations"
      @source-updated="refreshSources()" />
      <AddSomethingButton
        v-if="!isAddingSource && this.userManagementStore.hasFacilitiesWritePermission()"
        :buttonText="this.$t('sourceInfoView.addSource')"
        @button-clicked="addSource()"/>
    </div>
  </div>
</template>

<script>
import useTransitStore from '@/stores/TransitStore';
import useUserManagementStore from '@/stores/UserManagementStore';
import AddSomethingButton from '@/components/generic/AddSomethingButton.vue';
import SourceInfoViewCard from '@/components/facilityTabComponents/SourceInfoViewCard.vue';
import SearchBar from '@/components/SearchBar.vue';

export default {
  name: 'SourceInfoView',
  components: {
    AddSomethingButton,
    SourceInfoViewCard,
    SearchBar,
  },
  props: ['filteredLocations'],
  data() {
    return {
      isAddingSource: false,
      sourcesInfo: {},
      filterValues: {
        locationIds: [],
        searchValue: '',
      },
    };
  },
  setup() {
    const transitStore = useTransitStore();
    const userManagementStore = useUserManagementStore();
    return {
      transitStore,
      userManagementStore,
    };
  },
  methods: {
    addSource() {
      this.isAddingSource = true;
    },
    async onFilterUpdated(searchValue) {
      this.filterValues = { ...searchValue };
      await this.refreshSources();
    },
    async refreshSources() {
      this.sourcesInfo = [];
      this.filterValues.locationIds = [];
      this.isAddingSource = false;
      if (this.filteredLocations.length === 0) {
        return;
      }
      this.filteredLocations.forEach((filteredLocation) => { this.filterValues.locationIds.push(filteredLocation.id); });
      this.sourcesInfo = await this.transitStore.fetchFilteredStreams(this.filterValues);
    },
  },
  async mounted() {
    await this.transitStore.fetchLocation();
    await this.transitStore.fetchStreams();
    this.sourcesInfo = this.transitStore.streams;
  },
  watch: {
    filteredLocations: 'refreshSources',
  },
};
</script>
