<template>
  <router-view />
</template>

<script>
import useUserManagementStore from '@/stores/UserManagementStore';

export default {
  name: 'App',
  setup() {
    return {
    };
  },
  mounted() {
    window.addEventListener('mousemove', useUserManagementStore().setUserActivityOn);
    window.addEventListener('keydown', useUserManagementStore().setUserActivityOn);
    window.addEventListener('touchstart', useUserManagementStore().setUserActivityOn);
  },
  unmounted() {
    window.removeEventListener('mousemove', useUserManagementStore().setUserActivityOn);
    window.removeEventListener('keydown', useUserManagementStore().setUserActivityOn);
    window.removeEventListener('touchstart', useUserManagementStore().setUserActivityOn);
  },
};
</script>
