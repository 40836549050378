import i18n from '@/locale/i18n';
import moment from 'moment';

export function getLocalDateTimeFromTimestamp(utcStrDate) {
  return moment(utcStrDate).local().format(i18n.global.t('date.format'));
}

export function getDuration(utcStartStrDate, utcEndStrDate) {
  const transitDuration = moment(utcEndStrDate).diff(moment(utcStartStrDate));
  const hours = moment.utc(transitDuration).hours();
  const minutes = moment.utc(transitDuration).minutes();
  const seconds = moment.utc(transitDuration).seconds();
  let durationString = '';
  if (hours > 0) durationString = `${hours}${i18n.global.t('date.hour')} `;
  if (minutes > 0) durationString += `${minutes}${i18n.global.t('date.minute')} `;
  if (seconds > 0) durationString += `${seconds}${i18n.global.t('date.second')}`;
  return durationString;
}

export function getTimestampFromLocalDate(localStrDate) {
  // momentjs assume local time, which is what we want to convert to unix timestamp
  return moment(localStrDate).unix();
}

export function getLocalDateTimeFromTimestampInFilter(utcStrDate) {
  return moment(utcStrDate).local().format('YYYY-MM-DDTHH:mm');
}
