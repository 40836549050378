import { defineStore } from 'pinia';
import {
  getAPIData,
  putAPIData,
  postAPIData,
  deleteAPIData,
} from '@/utils/api';
import i18n from '@/locale/i18n';
import emitter from '@/utils/create_emitter';

export const REFRESH_INTERVAL = 300000; /* 5 minutes */

const userManagementStore = defineStore('userManagementStore', {
  state: () => ({
    userManagement: {
      privateSessionInfo: {},
      privateAccountInfo: {},
    },
    users: [],
    roles: [],
    userActivity: true,
    refreshTokenJobId: -1,
  }),
  actions: {
    setSignInInfo(signInInfo) {
      this.userManagement.privateSessionInfo.token = signInInfo.token;
      this.userManagement.privateAccountInfo.id = signInInfo.user?.id;
      this.userManagement.privateAccountInfo.name = signInInfo.user?.name;
      this.userManagement.privateAccountInfo.surname = signInInfo.user?.surname;
      this.userManagement.privateAccountInfo.email = signInInfo.user?.email;
      this.userManagement.privateSessionInfo.isReset = signInInfo.is_reset;
      this.userManagement.privateAccountInfo.permissions = this.getPermissionsFromToken(signInInfo.token);
      sessionStorage.userManagement = JSON.stringify(this.userManagement);
    },
    getUserToken() {
      return this.userManagement.privateSessionInfo?.token;
    },
    getUserId() {
      return this.userManagement.privateAccountInfo.id;
    },
    getUserName() {
      return this.userManagement.privateAccountInfo.name;
    },
    getUserSurname() {
      return this.userManagement.privateAccountInfo.surname;
    },
    getEmail() {
      return this.userManagement.privateAccountInfo.email;
    },
    hasPermissionName(permissionName) {
      return this.userManagement?.privateAccountInfo?.permissions?.some((permission) => permission === permissionName);
    },
    hasFacilitiesWritePermission() {
      return this.hasPermissionName('facilities_write');
    },
    hasAllUsersWritePermission() {
      return this.hasPermissionName('all_users_write');
    },
    signOut() {
      this.userManagement.privateSessionInfo = {};
      this.userManagement.privateAccountInfo = {};
      sessionStorage.userManagement = JSON.stringify(this.userManagement);
    },
    isSignedIn() {
      if (Object.keys(this.userManagement.privateSessionInfo).length === 0
      && sessionStorage.userManagement) {
        this.userManagement = JSON.parse(sessionStorage.userManagement);
      }

      return this.userManagement.privateSessionInfo.token !== undefined
        && this.userManagement.privateAccountInfo.id !== undefined
        && !Number.isNaN(this.userManagement.privateAccountInfo.id);
    },
    setAccountInfo(accountInfo) {
      this.userManagement.privateAccountInfo.name = accountInfo.name;
      this.userManagement.privateAccountInfo.surname = accountInfo.surname;
      this.userManagement.privateAccountInfo.email = accountInfo.email;
      sessionStorage.userManagement = JSON.stringify(this.userManagement);
    },
    getAccountInfo() {
      return this.userManagement.privateAccountInfo;
    },
    setUsers(fetchedUsers) {
      this.users = [...this.getPrepareUsersList(fetchedUsers)];
    },
    getPrepareUsersList(fetchedUsers) {
      const usersList = [];
      fetchedUsers.forEach((fetchedUser) => {
        const newuser = { ...fetchedUser };
        const [userRole] = fetchedUser.roles;
        delete newuser.roles;
        newuser.role = userRole;
        usersList.push(newuser);
      });
      return usersList;
    },
    getUsers() {
      return this.users;
    },
    setUserRoles(rolesList) {
      this.roles = [];
      rolesList.forEach((role) => this.roles.push({
        id: role.id,
        value: i18n.global.t(`userRoleSelector.${role.name}`),
        name: role.name,
      }));
    },
    getUserDataFromToken(token) {
      if (!token) {
        return {};
      }
      let base64Url = token.split('.')[1];
      base64Url = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      return JSON.parse(window.atob(base64Url))?.userData;
    },
    getPermissionsFromToken() {
      const userData = this.getUserDataFromToken(this.userManagement.privateSessionInfo.token);
      if (!userData) {
        return {};
      }
      return userData.permissions;
    },
    isUserReset() {
      return this.userManagement.privateSessionInfo.isReset;
    },
    async addUser(userInfo) {
      try {
        const response = await postAPIData('/users', this.getUserToken(), {
          body: JSON.stringify(userInfo),
        });
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async editUser(userInfo) {
      try {
        const addUserEndpointUrl = `/users/${userInfo.id}`;
        const response = await putAPIData(addUserEndpointUrl, this.getUserToken(), {
          body: JSON.stringify(userInfo),
        });
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteUser(userId) {
      try {
        const deleteSuccessfull = await deleteAPIData(`/users/${userId}`, this.getUserToken());
        if (!deleteSuccessfull) {
          return false;
        }
        emitter.emit('user_deleted');
        return true;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async fetchRoles() {
      try {
        const roles = await getAPIData('/roles', this.getUserToken());
        this.setUserRoles(roles);
      } catch (error) {
        console.error(error);
      }
    },
    async putUserRole(userId, roleIds) {
      try {
        return putAPIData(`/users/${userId}/roles`, this.getUserToken(), {
          body: JSON.stringify({ role_ids: roleIds }),
        });
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async fetchUsers() {
      try {
        const users = await getAPIData('/users', this.getUserToken());
        this.setUsers(users);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchFilteredUsers(filterValues) {
      const queryParams = new URLSearchParams();
      try {
        if (filterValues.searchValue) {
          queryParams.append('search_value', filterValues.searchValue);
        }
        const tempUsers = await getAPIData(`/users?${queryParams.toString()}`, this.getUserToken());
        return this.getPrepareUsersList(tempUsers);
      } catch (error) {
        console.error(error);
      }
      return [];
    },
    async handleSignIn(username, passwd, authMethod) {
      try {
        const bodyData = {
          user: username,
          password: passwd,
        };
        let url = '/auth/login';
        if (authMethod === 'active_directory') {
          url = '/auth/login_ldap';
        }
        const signInInfo = await postAPIData(url, this.getUserToken(), {
          body: JSON.stringify(bodyData),
        });
        if (signInInfo?.status === 403) {
          throw new Error('Wrong login credentials');
        }
        this.setSignInInfo(signInInfo);
        return this.signInfo;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async fetchAccountInfo() {
      let accountInfo = {};
      if (this.isSignedIn()) {
        accountInfo = await getAPIData(`/users/${this.getUserId()}`, this.getUserToken());
        this.setAccountInfo(accountInfo);
      }
      return accountInfo;
    },
    async handleUserData(accountInfo) {
      let response = '';
      try {
        response = await putAPIData(`/users/${this.getUserId()}`, this.getUserToken(), {
          body: JSON.stringify(accountInfo),
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
      this.setAccountInfo({
        email: accountInfo.email,
        name: accountInfo.name,
        surname: accountInfo.surname,
      });
      return response;
    },
    async requestSingleUsePassword(userId) {
      try {
        const requestResult = await putAPIData(`/users/${userId}/single-use-password`, this.getUserToken());
        return requestResult.single_use_password;
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    async resetPassword(singleUsePassword, newPassword) {
      const requestBody = {
        single_use_password: singleUsePassword,
        new_password: newPassword,
      };
      try {
        const requestResult = await putAPIData(`/users/${this.getUserId()}/reset-password`, this.getUserToken(), {
          body: JSON.stringify(requestBody),
        });
        if (!requestResult.isNoContent || requestResult === '') {
          return false;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
      return true;
    },
    async changePassword(currentPassword, newPassword) {
      const requestBody = {
        current_password: currentPassword,
        new_password: newPassword,
      };
      try {
        const requestResult = await putAPIData(`/users/${this.getUserId()}/password`, this.getUserToken(), {
          body: JSON.stringify(requestBody),
        });
        return requestResult;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    setUserActivityOn() {
      this.userActivity = true;
    },
    async refreshTokenIfUserActivity() {
      if (this.userActivity) {
        this.refreshToken();
        this.userActivity = false;
      }
    },
    startRefreshTokenPeriodicJob() {
      this.refreshTokenJobId = setInterval(this.refreshTokenIfUserActivity, REFRESH_INTERVAL);
    },
    stopRefreshTokenPeriodicJob() {
      clearInterval(this.refreshTokenJobId);
    },
    async refreshToken() {
      this.userManagement.privateSessionInfo.token = await getAPIData('/auth/refresh_token', this.getUserToken());
    },
  },
});

export default userManagementStore;
