<template>
  <button type="button" @click="closeToast()" v-show=this.open
    :class="[
    'fixed justify-center top-4 z-50 w-auto rounded-md px-3 py-2 opacity-50 animate-fade',
    getStyle()]">
    <div class="flex items-center space-x-2">
      <span class="text-3xl">
        <i class="w-5 h-5">
          <img class="w-5 h-5" :src="getToastIcon()" alt="Toast icon">
        </i></span>
      <p class="font-bold">{{ toastText }}</p>
    </div>
  </button>
</template>

<script>
import { getIcon } from '@/utils/icons';

export default {
  name: 'ToastComponent',
  data() {
    return {
      open: false,
      timer: null,
      toastType: 'info',
      toastText: '',
    };
  },
  methods: {
    triggerToast() {
      if (this.open) return;

      this.open = true;
      if (this.timer) clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.open = false;
      }, 5000);
    },
    closeToast() {
      this.open = false;
    },
    openToast(toastInfo) {
      this.toastType = toastInfo.toastType;
      this.toastText = toastInfo.toastText;
      if (this.toastType !== 'info'
       && this.toastType !== 'warn'
       && this.toastType !== 'error') {
        this.toastType = 'info';
      }
      this.triggerToast();
    },
    getStyle() {
      if (this.toastType === 'info') return 'bg-blue-400';
      if (this.toastType === 'warn') return 'bg-amber-400';
      if (this.toastType === 'error') return 'bg-red-400';
      return 'bg-black';
    },
    getToastIcon() {
      if (this.toastType === 'info') return getIcon('infoIcon');
      if (this.toastType === 'warn') return getIcon('warnIcon');
      if (this.toastType === 'error') return getIcon('errorIcon');
      return '';
    },
  },
};
</script>
