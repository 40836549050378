<template>
  <div v-if=this.isOpen class="fixed top-0 left-0 w-full h-full z-40 bg-black bg-opacity-30 items-center flex">
    <div v-if=this.isOpen class="rounded-2xl shadow-2xl bg-white w-fit max-w-xl h-fit mx-auto px-4
    flex flex-col justify-between align-center py-4 gap-4">
      <div class="flex flex-col gap-1">
        <p class="text-xl justify-start my-4" v-show="modalInfo.modalTitle">{{ modalInfo.modalTitle }}</p>
        <p v-show="modalInfo.modalText">{{ modalInfo.modalText }}</p>
        <p class="font-bold" v-show="modalInfo.modalQuestion">{{ modalInfo.modalQuestion }}</p>
      </div>
      <div :class="['flex mx-4 mt-2',
        modalInfo.showCancelButton? 'justify-between': 'justify-center']">
        <button @click="cancelAndClose" v-show="modalInfo.showCancelButton"
          class="px-4 py-2 text-gray-600 hover:font-bold underline underline-offset-4 hover:decoration-4 hover:text-rose-950">
          {{ $t('facilityInfoViewCard.cancel') }}
        </button>
        <button @click="acceptAndClose"
          class="px-3 py-1 bg-sky-500 text-white rounded
            hover:bg-sky-700 transition-colors duration-300 disabled:bg-gray-300">
            {{ modalInfo.acceptButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/locale/i18n';

const baseModalInfo = {
  modalTitle: '',
  modalText: '',
  modalQuestion: '',
  showCancelButton: true,
  acceptButtonText: i18n.global.t('modalDialog.defaultAcceptButton'),
  signalOnAccept: '',
  signalOnCancel: '',
};

export default {
  name: 'ModalDialog',
  components: {
  },
  data() {
    return {
      isOpen: false,
      modalInfo: baseModalInfo,
    };
  },
  methods: {
    openModal(modalInfo) {
      this.modalInfo = { ...this.modalInfo, ...modalInfo };
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.modalInfo = { ...baseModalInfo };
    },
    cancelAndClose() {
      if (this.modalInfo.signalOnCancel) {
        this.$emit(this.modalInfo.signalOnCancel);
      }
      this.closeModal();
    },
    acceptAndClose() {
      if (this.modalInfo.signalOnAccept) {
        this.$emit(this.modalInfo.signalOnAccept);
      }
      this.closeModal();
    },
  },
};
</script>
