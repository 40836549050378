<template>
  <SingleSelectCombo
    ref="singleSelectCombo"
    :buttonText="this.buttonText"
    :uniqueComboName="this.uniqueComboName"
    :comboOptions="this.transitTypesArray"
    :restoreFromSessionStorage="false"
    :disabled="this.nonEditable"
    @comboSelectionChanged="TransitTypeSelectorUpdated"/>
</template>

<script>
import SingleSelectCombo from '@/components/generic/SingleSelectCombo.vue';

export default {
  name: 'TransitTypeSelector',
  components: {
    SingleSelectCombo,
  },
  data() {
    const selectedOption = { id: '', value: '', name: '' };
    return {
      transitTypesArray: [
        { id: 'road', name: 'road', value: this.$t('transitTypeFilter.road') },
        { id: 'train', name: 'train', value: this.$t('transitTypeFilter.train') },
        { id: 'crane', name: 'crane', value: this.$t('transitTypeFilter.crane') },
      ],
      selectedOption,
      buttonText: this.$t('transitTypeFilter.transitType'),
    };
  },
  props: {
    uniqueComboName: {
      type: String,
      required: true,
    },
    nonEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    TransitTypeSelectorUpdated(option) {
      this.selectedOption = { ...option };
      this.buttonText = this.getButtonText();
      this.$emit('update:transitTypeSelectorChanged', this.selectedOption.id);
    },
    getButtonText() {
      const buttonTextValue = this.selectedOption
        ? this.$t(`transitTypeFilter.${this.selectedOption.name}`)
        : this.$t('transitTypeFilter.transitType');
      return buttonTextValue;
    },
    setPreselectedOptionId(preselectedOptionId) {
      this.transitTypesArray.forEach((transitType) => {
        if (transitType.id === preselectedOptionId) {
          this.selectedOption = { ...transitType };
          this.buttonText = this.getButtonText();
          this.$refs.singleSelectCombo.setPreselectedOption(this.selectedOption);
        }
      });
    },
  },
};
</script>
