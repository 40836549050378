<template>
  <MultiSelectCombo ref="multiSelectCombo" :buttonText="this.$t('statsTypeFilter.statsType')" :uniqueComboName="this.uniqueComboName"
    :comboOptions="this.statsTypeArray" signalOnChange="update:filterByStatsType"/>
</template>

<script>
import MultiSelectCombo from './generic/MultiSelectCombo.vue';

export default {
  name: 'StatsTypeFilter',
  components: {
    MultiSelectCombo,
  },
  data() {
    return {
      statsTypeArray: [],
    };
  },
  props: {
    uniqueComboName: {
      type: String,
      required: true,
    },
  },
  methods: {
    computeStatsTypeArray(statsTypes) {
      this.statsTypeArray = [];
      Object.entries(statsTypes).forEach(([statKey, statLookup]) => {
        if (!statLookup.hidden) {
          this.statsTypeArray.push({ id: statKey, value: this.$t(statLookup.name) });
        }
      });
    },
    selectAllOptions() {
      this.$refs.multiSelectCombo.selectAll();
    },
  },
  mounted() {
    this.emitter.on('stats-types-updated', (statsTypes) => this.computeStatsTypeArray(statsTypes));
    this.emitter.on('stats-types-switched', (optionIds) => this.$refs.multiSelectCombo?.switchOptionsToggle(optionIds));
  },
  unmounted() {
    this.emitter.off('stats-types-updated');
    this.emitter.off('stats-types-switched');
  },
};
</script>
