<template>
    <div class="relative w-full bg-[#1e3c53] rounded-xl shadow px-3 mb-5 py-10 flex flex-col items-center gap-10">
        <FacilityInfoView
          @filtered-facilities="setFilteredFacilities"/>
        <LocationInfoView
          v-if="this.transitStore.facilities?.length > 0"
          @filtered-locations="setFilteredLocations"
          :filteredFacilities="this.filteredFacilities"/>
        <SourceInfoView
          v-if="this.transitStore.locations?.length > 0"
          :filteredLocations="this.filteredLocations" />
    </div>
</template>

<script>
import useTransitStore from '@/stores/TransitStore';
import FacilityInfoView from '@/components/facilityTabComponents/FacilityInfoView.vue';
import LocationInfoView from '@/components/facilityTabComponents/LocationInfoView.vue';
import SourceInfoView from '@/components/facilityTabComponents/SourceInfoView.vue';

export default {
  name: 'FacilityTab',
  components: {
    FacilityInfoView,
    LocationInfoView,
    SourceInfoView,
  },
  data() {
    return {
      filteredLocations: [],
      filteredFacilities: [],
    };
  },
  setup() {
    const transitStore = useTransitStore();
    return {
      transitStore,
    };
  },
  methods: {
    setFilteredFacilities(filteredFacilities) {
      this.filteredFacilities = filteredFacilities;
    },
    setFilteredLocations(filteredLocations) {
      this.filteredLocations = filteredLocations;
    },
  },
  async beforeCreate() {
    await this.transitStore.fetchFacilities();
    await this.transitStore.fetchLocation();
    await this.transitStore.fetchStreams();
  },
};
</script>
