<template>
  <div class="bg-gray-50 m-3 w-3/4 rounded-2xl relative">
    <button
      v-show="this.transit.videos?.length > 0"
      class="absolute top-2 right-2 min-w-max h-8 z-20 rounded-md items-center
        border bg-gray-50 hover:bg-gray-200 p-1 transition-colors duration-200
        ease-in-out transform hover:shadow-lg hover:scale-105"
      v-on:click="this.emitter.emit('click-on-video', transit)"
      title="Show video viewer">
      <img
        class="min-w-max h-6"
        :src="getIcon('downloadVideoIcon')"
        alt="open popupVideo"/>
    </button>
    <div class="px-3 py-4 flex gap-6 justify-left relative">
      <CardLabel :cardLabelText="getLocationName()" :bold="true"/>
      <div class="grid grid-cols-1 p-3 content-around">
        <div class="flex justify-center items-start gap-2">
          <img :src="this.locationInfo?.icon" class="w-14 h-14"
            alt="transit type icon" @click="copyTransitId">
        </div>
        <div class="text-left text-xs whitespace-nowrap">
          {{ getLocalDateTimeFromTimestamp(transit.start_time) }} <br/>
          <strong>{{ this.$t('transitCard.duration') }}</strong> {{ getTransitDuration(transit.start_time, transit.end_time) }}
        </div>
      </div>
      <div v-if="isTrainTransit()">
        <TransitSummary :transit_summary="transit_summary" />
      </div>
      <div v-else class="flex gap-3 overflow-auto">
        <CardSection
          v-for="(section, index) in sections"
          :key="section.section_id"
          :section="section"
          :sectionIndex="index"
          @update-value="updateSectionValue"
        />
      </div>
    </div>
    <div v-if="showWagons">
      <button @click="toggleShowWagons"
      class="w-full text-gray-500 rounded-t-lg
      border-x border-t border-x-gray-200 border-b-none text-center
      bg-gradient-to-b from-gray-200 via-30% via-gray-200 to-white
      hover:from-gray-300 via-30% via-gray-300 hover:to-white pb-2">
      {{ $t('transitCard.lessInfo') }}
      </button>
      <WagonSection :sections="sections"/>
    </div>
    <div v-if="isTrainTransit()">
      <button @click="toggleShowWagons"
      class="w-full text-gray-500 bg-gray-200 hover:bg-gray-300 rounded-b-lg shadow-lg
      text-center">{{showWagons ? $t('transitCard.lessInfo') : $t('transitCard.moreInfo')}}</button>
    </div>
  </div>
</template>

<script>
import useTransitStore from '@/stores/TransitStore';
import valueToPercent from '@/utils/math';
import { VEHICLE_INFO_TYPES } from '@/utils/types';
import { getIconName, getIcon } from '@/utils/icons';
import { getLocalDateTimeFromTimestamp, getDuration } from '@/utils/date';
import { getStatisticalTypeName } from '@/utils/lookup';
import CardSection from './CardSection.vue';
import WagonSection from './WagonSection.vue';
import TransitSummary from './TransitSummary.vue';
import CardLabel from './CardLabel.vue';

const sectionTypeOrder = [
  'vehicle',
  'vehicleclassification',
  'vehicledetection',
  'license plate',
  'trailer license plate',
  'universalcontainer',
  'BIC',
  'adr',
  'containertare',
  'sealreader',
  'sealdetection',
  'vehicleinformation',
  'vehicle information',
];

export default {
  name: 'TransitCard',
  props: ['transit'],
  components: {
    CardSection,
    WagonSection,
    TransitSummary,
    CardLabel,
  },
  setup() {
    return {
      transitStore: useTransitStore(),
      getLocalDateTimeFromTimestamp,
      getTransitDuration: getDuration,
      getIcon,
    };
  },
  data() {
    return {
      vehicleInfoTypes: VEHICLE_INFO_TYPES,
      sections: [],
      wagons: [],
      locationInfo: {},
      showWagons: false,
      transit_summary: {},
    };
  },
  methods: {
    getLocationName() {
      return this.locationInfo.alias ? this.locationInfo.alias : this.locationInfo.name.replaceAll('_', ' ');
    },
    isTrainTransit() {
      return this.locationInfo.transit_type === 'train';
    },
    toggleShowWagons() {
      this.showWagons = !this.showWagons;
    },
    getSection(metaObject) {
      let section = {
        section_id: 0,
        type: '',
        typeConfidence: '',
        icon: '',
        values: [],
        cameras: [],
        imageIds: {},
        imageSources: {},
        childMetaObjects: [],
      };
      section = this.setSectionTypeAndIcon(metaObject, section);
      section = this.setSectionValues(metaObject, section);
      section = this.setDetectionAndImages(metaObject, section);
      return section;
    },
    getFlatChildSectionsList(childMetaObjects) {
      let metaObjectsList = [];
      if (childMetaObjects === null
      || typeof childMetaObjects === 'undefined'
      || childMetaObjects.length === 0) {
        return [];
      }
      childMetaObjects.forEach((childMetaObject) => {
        const noChildrenChildMetaObject = { ...childMetaObject };
        delete noChildrenChildMetaObject.child_meta_objects;
        metaObjectsList.push(noChildrenChildMetaObject);
        metaObjectsList = metaObjectsList.concat(this.getFlatChildSectionsList(childMetaObject.child_meta_objects));
      });
      return metaObjectsList;
    },
    setSectionTypeAndIcon(metaObject, section) {
      const newSection = { ...section };
      const readingType = metaObject?.reading?.type;
      const classificationType = metaObject?.classifications?.[0]?.type;
      const classificationValue = metaObject?.classifications?.[0]?.value;
      newSection.type = readingType ?? classificationType ?? '';

      if (newSection.type === 'license plate' && classificationValue.includes('trailer')) newSection.type = 'trailer license plate';
      newSection.typeConfidence = metaObject?.reading?.confidence ?? metaObject?.classifications?.[0]?.confidence ?? '';
      newSection.icon = getIconName(newSection.type, classificationValue);
      if (newSection.type === 'vehicleclassification'
       || newSection.type === 'vehicledetection') newSection.type = 'vehicle';
      if (this.vehicleInfoTypes.includes(newSection.type)) newSection.type = 'vehicle information';

      return newSection;
    },
    setSectionValues(metaObject, section) {
      const newSection = { ...section };
      newSection.section_id = metaObject.id;
      newSection.values = [];
      metaObject?.classifications?.forEach((classification) => {
        const content = {
          db_id: classification?.id,
          value: classification?.value?.replaceAll('_', ' ') ?? '',
          value_type: 'classification',
          confidence: classification?.confidence ?? '',
          edited: classification?.edited,
          check_digit_passed: true,
        };
        newSection.values.push(content);
      });
      if (metaObject?.reading) {
        const content = {
          db_id: metaObject.reading.id,
          value: metaObject.reading.value?.replaceAll('_', ' ') ?? '',
          value_type: 'reading',
          confidence: metaObject.reading.confidence ?? '',
          edited: metaObject.reading.edited,
          check_digit_passed: true,
          check_digit_str: '',
        };
        metaObject.reading.validations?.forEach((validation) => {
          if (validation.value === 1) {
            content.check_digit_passed = false;
            content.check_digit_str
            += this.$t('transitCard.checkDigitFailed', { validationType: validation.type, confidence: valueToPercent(content.confidence) });
          }
        });
        newSection.values.push(content);
      }

      return newSection;
    },
    setDetectionAndImages(metaObject, section) {
      const newSection = { ...section };

      let detectionTime = 0;
      let detectionRoi = {};
      let detectionSourceId = '';
      let detectionTypeName = '';
      let additionalStreamImages = [];

      const getImageObject = (image, selectedCriteria) => (
        {
          criteria: selectedCriteria,
          time: detectionTime,
          roi: (image.stream_id === detectionSourceId) ? detectionRoi : null,
          id: image.id,
          stream_id: image.stream_id,
          stream_alias: image.stream_alias,
          width: image.width,
          height: image.height,
        }
      );

      const processAdditionalStreamImages = (image) => {
        image.criteria_list?.forEach((selectedCriteria) => {
          if (selectedCriteria !== 'additional_stream') return;
          additionalStreamImages.push(getImageObject(image, selectedCriteria));
        });
      };

      const processImages = (image) => {
        image.criteria_list?.forEach((selectedCriteria) => {
          if (selectedCriteria === 'additional_stream') return;
          const imageObject = { ...getImageObject(image, selectedCriteria) };
          imageObject.detectionTypeName = detectionTypeName;
          imageObject.additionalStreamImages = [...additionalStreamImages];
          newSection.images.push(imageObject);
        });
      };

      const processDetection = (metaobjectDetection) => {
        detectionTime = metaobjectDetection.time;
        detectionRoi = {
          x: metaobjectDetection.x,
          y: metaobjectDetection.y,
          width: metaobjectDetection.width,
          height: metaobjectDetection.height,
        };
        detectionSourceId = metaobjectDetection.stream_id;
        additionalStreamImages = [];
        metaobjectDetection.images?.forEach(processAdditionalStreamImages);
        metaobjectDetection.images?.forEach(processImages);
      };
      newSection.images = [];

      metaObject?.classifications?.forEach((classification) => {
        detectionTypeName = getStatisticalTypeName(classification.type);
        classification.detections?.forEach(processDetection);
      });

      if (metaObject.reading) {
        detectionTypeName = getStatisticalTypeName(metaObject.reading.type);
        metaObject.reading.detections?.forEach(processDetection);
      }
      return newSection;
    },
    async updateSectionValue({ index, updatedValue, sectionIndex }) {
      const currentSection = this.sections[sectionIndex].values[index];
      if (currentSection.value === updatedValue) return;
      let putResult = false;
      if (currentSection.value_type === 'reading') {
        putResult = await this.transitStore.putReadingValue({ readingId: currentSection.db_id, newValue: updatedValue });
      } else {
        putResult = await this.transitStore.putClassificationValue({ classificationId: currentSection.db_id, newValue: updatedValue });
      }
      if (putResult) {
        currentSection.edited = true;
        currentSection.value = updatedValue;
        this.emitter.emit('show-toast', { toastType: 'info', toastText: this.$t('transitCard.successfulEdit') });
      } else {
        const replaceValue = currentSection.value;
        currentSection.value = replaceValue;
        this.emitter.emit('show-toast', { toastType: 'error', toastText: this.$t('transitCard.errorOnEdit') });
      }
    },
    updateSection() {
      this.sections = [];
      this.transit?.meta_objects?.forEach((metaObject) => {
        const section = this.getSection(metaObject);
        const childMetaObjectsList = this.getFlatChildSectionsList(metaObject.child_meta_objects);
        childMetaObjectsList.forEach((childMetaObject) => {
          section.childMetaObjects.push(this.getSection(childMetaObject));
        });
        this.sections.push(section);
      });
      this.sortSections();
    },
    sortSections() {
      this.sections.sort((section1, section2) => {
        if (sectionTypeOrder.indexOf(section1.type) < sectionTypeOrder.indexOf(section2.type)) {
          return -1;
        }
        if (sectionTypeOrder.indexOf(section1.type) > sectionTypeOrder.indexOf(section2.type)) {
          return 1;
        }
        if (section1.section_id < section2.section_id) {
          return -1;
        }
        if (section1.section_id > section2.section_id) {
          return 1;
        }
        return 0;
      });
    },
    updateLocationInfo() {
      this.locationInfo = this.transitStore.locations.find((location) => location.id === this.transit.location_id);
      if (!this.locationInfo) {
        return;
      }
      this.locationInfo.icon = getIcon(getIconName(this.locationInfo.transit_type));
    },
    updateSummaryInfo() {
      if (!this.isTrainTransit()) return;
      this.transit_summary = {
        wagons: 0,
        containers: 0,
        adr: 0,
        direction: '',
      };

      this.transit_summary.direction = this.transit.direction;
      this.sections.forEach((singleSection) => {
        if (singleSection.type === 'universalwagon') {
          this.transit_summary.wagons += 1;
          singleSection.childMetaObjects.forEach((childSection) => {
            if (childSection.type.toUpperCase() === 'BIC') this.transit_summary.containers += 1;
            if (childSection.type.toUpperCase() === 'ADR') this.transit_summary.adr += 1;
          });
        }
      });
    },
    copyTransitId() {
      navigator.clipboard.writeText(this.transit.id);
    },
  },
  beforeMount() {
    this.updateSection();
    this.updateLocationInfo();
    this.updateSummaryInfo();
  },
  watch: {
    transit() {
      this.updateSection();
      this.updateLocationInfo();
      this.updateSummaryInfo();
    },
  },
};
</script>
