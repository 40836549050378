<template>
  <div v-for="(wagon, index) in wagons" :key="wagon" class="m-5">
    <div class="px-6 py-4 flex gap-5 justify-left overflow-auto bg-gray-200 border
    border-gray-300 rounded-lg shadow-lg">
      <CardSection
        class="bg-gray-50"
        v-for="section in wagon"
        :key="section.type"
        :sectionIndex="index"
        :section="section"
      />
    </div>
    <div class="flex">
      <img :src='wagonLeftSide' class="w-7 h-7" alt="Wagon left side"/>
      <img :src='wagonCenter' class="w-full h-7" alt="Wagon center"/>
      <img :src='wagonRightSide' class="w-7 h-7" alt="Wagon right side"/>
    </div>
  </div>
</template>

<script>
import wagonLeftSide from '@/assets/img/wagon_left_side.png';
import wagonRightSide from '@/assets/img/wagon_right_side.png';
import wagonCenter from '@/assets/img/wagon_center.png';
import CardSection from './CardSection.vue';

export default {
  name: 'WagonSection',
  props: ['sections'],
  components: {
    CardSection,
  },
  data() {
    return {
      wagons: [],
      wagonLeftSide,
      wagonRightSide,
      wagonCenter,
    };
  },
  methods: {
    splitWagons() {
      this.wagons = [];
      let currentWagon = [];
      this.sections.forEach((section) => {
        if (section.type === 'video_frame_position') {
          return;
        }
        if (section.type !== 'universalwagon') {
          console.error('Train Transit Json misformed. Should only contain wagon sections!', section.type);
          return;
        }
        currentWagon.push(section);
        section.childMetaObjects.forEach((wagonSection) => {
          currentWagon.push(wagonSection);
        });
        this.wagons.push(currentWagon);
        currentWagon = [];
      });
    },
  },
  beforeMount() {
    this.splitWagons();
  },
};
</script>
