function round(value, decimals) {
  const roundedValue = Number(`${value}e${decimals}`);
  return Number(`${Math.round(roundedValue)}e-${decimals}`);
}

function valueToPercent(confidence, decimals = 0) {
  return `${round(confidence * 100, decimals)}%`;
}

export default valueToPercent;
