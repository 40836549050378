<template>
  <div class="bg-white rounded-2xl px-5 py-3 w-full shadow relative">
    <IconDropdownMenu
      v-if="this.currentLocationInfo && this.userManagementStore.hasFacilitiesWritePermission()"
      class="absolute top-2 end-2 min-w-max h-14"
      :comboOptions="this.dropdownOptions"
      :notShowIfSelected=false
      buttonIcon="kebabIcon"
      @update:selection-changed="showDeleteLocationWarning"/>
    <div v-if="this.currentLocationInfo" class="absolute top-5 left-16 flex flex-col gap-2 align-center items-center">
      <i class="min-w-max h-[70px]">
        <img
          :src="this.transitTypeIcon()"
          class="w-[70px] h-[70px] px-1 opacity-95 border border-gray-100 rounded shadow-md"
          alt="location type icon">
        <img v-if="this.warningMessage"
          :src="getIconImage('adminWarn')"
          class="w-8 h-8 absolute -top-3 left-7"
          alt="admin warning icon"
          :title="this.warningMessage">
      </i>
      <div>
        <p class="text-xs">
          {{ this.$t('locationInfoViewCard.created') }} {{ getLocalDateTimeFromTimestamp(this.currentLocationInfo.created_at) }}
        </p>
        <p class="text-xs">
          {{ this.$t('locationInfoViewCard.updated') }} <b>{{ getLocalDateTimeFromTimestamp(this.currentLocationInfo.updated_at) }}</b>
        </p>
      </div>
    </div>
    <div class="flex flex-col items-centered justify-center py-3">
      <div class="grid grid-cols-2 gap-1">
        <p class="place-self-end p-1 self-center">{{ $t('locationInfoViewCard.locationAlias') }}:</p>
        <EditableField
          :fieldId="`locationAlias${this.currentLocationInfo?.id}`"
          :fieldValidator="validateAlias"
          :nonEditable="!this.userManagementStore.hasFacilitiesWritePermission()"
          :value="this.locationInfo.alias"
          :placeholder="this.$t('locationInfoViewCard.locationAliasPlaceholder')"/>
        <p class="place-self-end p-1 self-center">{{ $t('locationInfoViewCard.locationName') }}:</p>
        <EditableField
          :fieldId="`locationName${this.currentLocationInfo?.id}`"
          :fieldValidator="validateName"
          :value="this.locationInfo.name"
          :nonEditable="!this.userManagementStore.hasFacilitiesWritePermission()"
          :placeholder="this.$t('locationInfoViewCard.locationNamePlaceholder')"/>
        <p class="place-self-end p-1 self-center">{{ $t('locationInfoViewCard.locationDescription') }}:</p>
        <EditableField
          :fieldId="`locationDescription${this.currentLocationInfo?.id}`"
          :fieldValidator="validateDescription"
          :value="this.locationInfo.description"
          :nonEditable="!this.userManagementStore.hasFacilitiesWritePermission()"
          :placeholder="this.$t('locationInfoViewCard.locationDescriptionPlaceholder')"/>
        <p class="place-self-end p-1 self-center">{{ $t('transitTypeFilter.transitType') }}:</p>
          <TransitTypeSelector
            class="place-self-start"
            ref="transitTypeSelector"
            uniqueComboName="transitTypeSelectorCombo"
            :nonEditable="!this.userManagementStore.hasFacilitiesWritePermission()"
            @update:transitTypeSelectorChanged="validateTransitType"
            :preselectedOptionId="this.locationInfo.transit_type"/>
        <p class="place-self-end p-1 items-center">{{ $t('locationInfoViewCard.locationFacility') }}:</p>
        <SelectorWithTags
          :doNotShowSelectButton="this.userManagementStore.hasFacilitiesWritePermission()"
          :availableOptions="this.facilities"
          :preselectedOptions="this.selectedFacilities"
          buttonIcon="locationPlusIcon"
          buttonItemIcon="locationIcon"
          @selection-changed="validateFacility" />
      </div>
      <hr v-if="this.errorMessage" class="border-t-1 border-dashed border-gray-300 mt-4 mb-4"/>
      <pre v-if="this.errorMessage" class="mt-2 text-red-700 text-sm">{{ this.errorMessage }}</pre>
    </div>
    <hr v-show="this.areFieldsEdited || !this.currentLocationInfo" class="border-t-1 border-dashed border-gray-300 mt-2 mb-1"/>
    <div class="flex gap-6 justify-center" v-show="this.areFieldsEdited || !this.currentLocationInfo">
      <button @click="cancelLocationAddOrEdit"
        class="mt-2 px-4 py-2 text-gray-600 hover:font-bold underline underline-offset-4 hover:decoration-4 hover:text-rose-950">
        {{ $t('locationInfoViewCard.cancelChangesButton') }}
      </button>
      <button @click="showLocationChangedNameWarningIfNecessary"
        class="mt-2 px-3 py-1 bg-sky-500 text-white rounded
          hover:bg-sky-700 transition-colors duration-300 disabled:bg-gray-300"
          :disabled="!this.submitButtonEnabled">
          {{ $t('locationInfoViewCard.applyChangesButton') }}
      </button>
    </div>
    <ModalDialog
      ref="locationInfoViewCardModal"
      @accept-change="submitLocation"
      @cancel-change="cancelLocationAddOrEdit"
      @delete-location="deleteLocation"/>
  </div>
</template>

<script>
import useUserManagementStore from '@/stores/UserManagementStore';
import EditableField from '@/components/generic/EditableField.vue';
import ModalDialog from '@/components/generic/ModalDialog.vue';
import IconDropdownMenu from '@/components/generic/IconDropdownMenu.vue';
import SelectorWithTags from '@/components/generic/SelectorWithTags.vue';
import TransitTypeSelector from '@/components/facilityTabComponents/TransitTypeSelector.vue';
import useTransitStore from '@/stores/TransitStore';
import { getLocalDateTimeFromTimestamp } from '@/utils/date';
import { getIcon } from '@/utils/icons';
import getAliasOrNameFromObject from '@/utils/objects';

export default {
  name: 'LocationInfoViewCard',
  props: {
    facilities: {
      type: Array,
      required: true,
    },
    currentLocationInfo: {
      type: Object,
      required: false,
    },
  },
  components: {
    EditableField,
    TransitTypeSelector,
    ModalDialog,
    IconDropdownMenu,
    SelectorWithTags,
  },
  data() {
    return {
      locationInfo: {
        facility_id: -1,
        alias: '',
        name: '',
        description: '',
        transit_type: '',
        streams: [],
      },
      errorMessage: '',
      errorMessages: {
        name: '',
        description: '',
        transit_type: '',
        facility: '',
      },
      warningMessage: '',
      submitButtonEnabled: false,
      areFieldsEdited: false,
      dropdownOptions: [
        {
          id: 'delete-location',
          name: this.$t('locationInfoViewCard.deleteLocationMenuText'),
          iconName: 'deleteIcon',
          buttonItemTextStyle: 'text-red-800',
        },
      ],
      selectedFacilities: [],
    };
  },
  setup() {
    const transitStore = useTransitStore();
    const userManagementStore = useUserManagementStore();
    return {
      getIconImage: getIcon,
      transitStore,
      userManagementStore,
      getLocalDateTimeFromTimestamp,
    };
  },
  methods: {
    validateAlias(editedAlias) {
      this.locationInfo.alias = editedAlias;
      this.joinValidations();
    },
    validateName(editedName) {
      this.errorMessages.name = (editedName?.length < 3) ? this.$t('locationInfoViewCard.nameMinimumLenght') : '';
      this.locationInfo.name = editedName;
      this.joinValidations();
    },
    validateDescription(editedDescription) {
      this.errorMessages.description = (editedDescription?.length < 10) ? this.$t('locationInfoViewCard.descriptionMinimumLenght') : '';
      this.locationInfo.description = editedDescription;
      this.joinValidations();
    },
    validateTransitType(transitType) {
      this.errorMessages.transit_type = (!transitType || transitType?.length === 0) ? this.$t('locationInfoViewCard.mustSelectOneTransitType') : '';
      this.locationInfo.transit_type = transitType;
      this.joinValidations();
    },
    validateFacility(selectedFacilities) {
      this.selectedFacilities = [...selectedFacilities];
      if (this.selectedFacilities?.length !== 1) {
        this.errorMessages.facility = this.$t('locationInfoViewCard.mustSelectFacility');
      } else {
        this.errorMessages.facility = '';
        this.locationInfo.facility_id = this.selectedFacilities[0].id;
      }
      this.joinValidations();
    },
    joinValidations() {
      this.errorMessage = Object.values(this.errorMessages).filter(Boolean).join('\n');
      this.areFieldsEdited = (JSON.stringify(this.currentLocationInfo) !== JSON.stringify(this.locationInfo));
      this.submitButtonEnabled = !this.errorMessage
      && this.areFieldsEdited
      && this.locationInfo.name
      && this.locationInfo.description
      && this.locationInfo.transit_type
      && this.locationInfo.facility_id > 0;
    },
    transitTypeIcon() {
      switch (this.locationInfo.transit_type) {
        case 'road':
          return getIcon('roadIcon');
        case 'train':
          return getIcon('trainIcon');
        case 'crane':
          return getIcon('craneIcon');
        default:
          return '';
      }
    },
    showLocationChangedNameWarningIfNecessary() {
      if (!this.currentLocationInfo || this.currentLocationInfo.name === this.locationInfo.name) {
        this.submitLocation();
        return;
      }
      const modalInfo = {
        modalTitle: this.$t('locationInfoViewCard.changedNameModalTitle'),
        modalText: this.$t('locationInfoViewCard.changedNameModalText'),
        modalQuestion: this.$t('locationInfoViewCard.changedNameModalQuestion'),
        showCancelButton: true,
        signalOnAccept: 'accept-change',
        signalOnCancel: 'cancel-change',
      };
      this.$refs.locationInfoViewCardModal.openModal(modalInfo);
    },
    async submitLocation() {
      const isEdition = Number.isInteger(this.locationInfo?.id);
      try {
        if (isEdition) {
          await this.transitStore.editLocation(this.locationInfo);
        } else {
          await this.transitStore.addLocation(this.locationInfo);
        }
        this.emitter.emit('show-toast', {
          toastType: 'info',
          toastText: this.$t(
            'locationInfoViewCard.locationAddedSuccessfully',
            {
              locationName: getAliasOrNameFromObject(this.locationInfo),
              what: isEdition ? this.$t('locationInfoViewCard.edited') : this.$t('locationInfoViewCard.added'),
            },
          ),
        });
      } catch (error) {
        console.error('Error adding location', error);
        this.emitter.emit('show-toast', {
          toastType: 'error',
          toastText: this.$t('locationInfoViewCard.errorAddingLocation', { locationName: getAliasOrNameFromObject(this.locationInfo) }),
        });
      }
      this.$emit('location-updated');
      this.areFieldsEdited = false;
    },
    cancelLocationAddOrEdit() {
      this.$emit('location-updated');
      this.locationInfo = { ...this.currentLocationInfo };
      this.errorMessage = '';
      this.areFieldsEdited = false;
    },
    resetLocation() {
      this.locationInfo = {
        facility_id: -1,
        name: '',
        description: '',
        transit_type: '',
      };
    },
    preprocessCurrentLocationInfo() {
      this.locationInfo = { ...this.currentLocationInfo };
      this.$refs.transitTypeSelector.setPreselectedOptionId(this.locationInfo?.transit_type);
      this.warningMessage = '';
      if (this.locationInfo.streams?.length <= 0) {
        this.warningMessage += this.$t('locationInfoViewCard.noSourcesConfigured');
      }
      this.selectedFacilities = [];
      if (this.locationInfo?.facility_id) {
        const selectedFacility = this.transitStore.facilities.find((facility) => facility.id === this.locationInfo.facility_id);
        this.selectedFacilities.push(selectedFacility);
      }
    },
    showDeleteLocationWarning() {
      const modalInfo = {
        modalTitle: this.$t('locationInfoViewCard.modalDeleteLocationTitle'),
        modalText: this.$t('locationInfoViewCard.modalDeleteLocationText', { who: getAliasOrNameFromObject(this.locationInfo) }),
        modalQuestion: this.$t('locationInfoViewCard.modalDeleteLocationQuestion'),
        showCancelButton: true,
        signalOnAccept: 'delete-location',
      };
      this.$refs.locationInfoViewCardModal.openModal(modalInfo);
    },
    async deleteLocation() {
      if (await this.transitStore.deleteLocation(this.currentLocationInfo)) {
        this.emitter.emit('show-toast', {
          toastType: 'info',
          toastText: this.$t('locationInfoViewCard.locationDeletedSuccessfully', { who: getAliasOrNameFromObject(this.locationInfo) }),
        });
        this.$emit('location-updated');
      } else {
        this.emitter.emit('show-toast', {
          toastType: 'error',
          toastText: this.$t('locationInfoViewCard.locationDeleteFailed', { who: getAliasOrNameFromObject(this.locationInfo) }),
        });
      }
    },
  },
  mounted() {
    this.preprocessCurrentLocationInfo();
  },
  watch: {
    currentLocationInfo: 'preprocessCurrentLocationInfo',
  },
};
</script>
